import React, {forwardRef, useEffect, useState} from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import styled from "styled-components";
import {DeleteOutlined, HolderOutlined, InfoCircleFilled} from "@ant-design/icons";
import {Button, Form, Input, Modal, Select} from "antd";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {changePoolsAction, getPoolsAction} from "../../redux/pageSlice";
import {Selector} from "antd-mobile";
import {PoolType} from "../../utils/types";
import {mediumSize} from "../../config";
import {useMediaQuery} from "usehooks-ts";

const Top = styled.div`
    display: flex;
    margin-bottom: 8px;
    align-items: center;
    gap: 16px;

    .info {
        width: 100%;
        padding: 20px 15px;
        border-radius: 3px;
        font-size: 14px;
        background: #2a323a;
        color: #9297a0;
        display: flex;
        align-items: center;

        svg {
            margin-right: 8px;
            width: 16px;
            height: 16px;
            flex: 1 0 auto;
        }
    }

    .actions {
        display: flex;
        gap: 16px;

        .ant-btn {
            background: #0d2d36 !important;
            border-radius: 5px;
            border-color: #26a59a !important;
            color: #ffffff !important;
            padding: 10px 46px;
            height: auto;
            line-height: 16px;
            font-size: 16px;

            &:hover {
                opacity: .8;
            }
        }
    }
`;

const StyledTest = styled.div`
    width: 100%;
    height: fit-content;
    padding: 24px 11vw;

    .ant-modal {
        .ant-modal-content {
            background: #192026;

            .ant-modal-confirm-paragraph {
                .ant-modal-confirm-title, .ant-modal-confirm-content {
                    color: #cdcdcd;
                }
            }
        }

        .ant-input {
            background: #192026 !important;
            color: #cdcdcd;
            border-radius: 5px;
            padding: 0 12px;
            line-height: 16px;
            border-color: #313a47;
            height: 38px;
            font-size: 16px;

            &::placeholder {
                color: #cdcdcd;
            }

        }
    }


    .adm-button {
        &:not(.adm-button-fill-none) {
            background: #26a59a !important;
            border: 1px solid #26a59a !important;
        }

        &.adm-button-fill-none {
            color: #26a59a;
        }
    }

    .adm-selector-item {
        background: #2c3139;
        color: #9297a0;
    }

    .adm-selector-item-active {
        color: #26a59a;
        background-color: #0d2d36;
    }

    .adm-selector-check-mark-wrapper {
        border-bottom: solid 8px #26a59a;
        border-right: solid 10px #26a59a;
    }

    h1 {
        color: #cdcdcd;
        font-size: 21px;
        font-weight: 600;
        margin-bottom: 18px;
    }

    .dnd-wrap {
        display: flex;
        flex-direction: column;
        background: #252d36;
        border: 1px solid #2b333e;
        border-radius: 3px;
        padding: 12px 40px;
    }

    .comments {
        list-style: none;
        padding: 0;

        li {
            display: flex;
            align-items: center;
            width: 100%;
            color: #81858b;
            padding: 20px;
            border-radius: 5px;
            margin-bottom: 20px;
            background: #1d252e;
            border: 1px solid #313a47;
            gap: 16px;

            .desktopDelete {
                margin-left: auto;
                display: flex;
                justify-content: flex-end;
            }

            > span {
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 14px;
                font-weight: 600;
                white-space: nowrap;


                .mobileDelete {
                    display: none;
                    margin-left: auto;
                }
            }

            .select-wrap {
                display: flex;
                width: 100%;

                > span {
                    padding: 0 11px;
                    display: flex;
                    align-items: center;
                    color: #9297a0;
                    justify-content: center;
                    font-size: 14px !important;
                    background: #2c3139;
                    border: 1px solid #313a47;
                    border-right: none;
                    height: 38px;
                    border-radius: 5px 0 0 5px;
                }

                .ant-select-arrow {
                    color: #313a47;
                }

                .ant-select {
                    height: auto;
                    width: 100%;

                    .ant-select-selector {
                        font-size: 14px !important;
                        background: #192026;
                        border-color: #313a47;
                        height: 38px;
                        width: 100%;
                        border-radius: 0 5px 5px 0;

                        .ant-select-selection-item {
                            color: #cdcdcd !important;
                        }
                    }
                }
            }

            .ant-input-wrapper {
                .ant-input-group-addon {
                    background: #2c3139;
                    border: 1px solid #313a47;
                    color: #9297a0;
                    border-radius: 5px 0 0 5px;
                    font-size: 14px;
                }

                .ant-input {
                    background: #192026;
                    color: #cdcdcd;
                    border-radius: 0 5px 5px 0;
                    padding: 0 12px;
                    line-height: 16px;
                    border-color: #313a47;
                    border-left: none;
                    height: 38px;
                    font-size: 16px;


                    &::placeholder {
                        color: #ffffff;
                    }

                }
            }

            &[data-rbd-placeholder-context-id] {
                //display: none !important;
                border: none;
                background: transparent;
            }

            svg {
                width: 20px;
                height: 32px;
                flex: 0 0 auto;
            }
        }
    }

    @media (${mediumSize}) {
        padding: 24px 6vw;

        .dnd-wrap {
            padding: 12px 20px;
        }

        .select-wrap {
            display: flex;
            width: 100%;

            > span {
                min-width: 75px;

            }
        }

        .ant-input-group-addon {
            width: 75px;
        }

        .comments {
            li {
                flex-direction: column;
                align-items: flex-start;

                .desktopDelete {
                    display: none;
                }

                > span {
                    width: 100%;

                    .mobileDelete {
                        display: flex;
                    }
                }
            }
        }

        ${Top} {
            flex-direction: column;
            align-items: flex-start;

            .actions {
                width: 100%;
                flex-wrap: wrap;

                .ant-btn {
                    flex: 1 1 auto;
                }
            }
        }
    }
`;

const Pool = forwardRef(
    ({
         Type,
         Password,
         Login,
         Address,
         id,
         dragHandleProps,
         onChange,
         onDelete,
         snapshot,
         matches,
         ...props
     }: any, ref) => {


        return (
            <li
                ref={ref}
                {...props}
                className={(snapshot.isDragging ? "hovering" : "")}
            >

                <span><HolderOutlined {...dragHandleProps}/> Пул: {id} <DeleteOutlined className={"mobileDelete"}
                                                                                       onClick={onDelete}
                                                                                       style={{color: "#ef5250"}}/></span>
                {/*<Input onChange={({target: {value}}) => onChange(id, "Type", value)}*/}
                {/*       style={{maxWidth: matches ? "none" : 130}}*/}
                {/*       addonBefore={"Тип"} value={Type}/>*/}
                {/*<Input onChange={({target: {value}}) => onChange(id, "Type", value)}*/}
                {/*       style={{maxWidth: matches ? "none" : 130}}*/}
                {/*       addonBefore={"Тип"} value={Type}/>*/}
                <div className="select-wrap">
                    <span>Тип</span>
                    <Select value={Type}
                            onSelect={(value) => onChange(id, "Type", value)}
                            getPopupContainer={() => document.querySelector(`.${StyledTest.styledComponentId}`)!}
                            options={[{
                                label: "btc",
                                value: "btc"
                            }, {
                                label: "kas",
                                value: "kas"
                            }, {
                                label: "ltc",
                                value: "ltc"
                            }]}/>
                </div>


                {/*<Input onChange={({target: {value}}) => onChange(id, "Type", value)}*/}
                {/*       style={{maxWidth: matches ? "none" : 130}}*/}
                {/*       addonAfter={<Select options={[{*/}
                {/*           title: "btc",*/}
                {/*           value: "btc"*/}
                {/*       },{*/}
                {/*           title: "ltc",*/}
                {/*           value: "ltc"*/}
                {/*       }]}>*/}

                {/*       </Select>} value={"Тип"} disabled/>*/}
                <Input onChange={({target: {value}}) => onChange(id, "Address", value)}
                       style={{width: matches ? "100%" : "140%"}}
                       addonBefore={"URL"} value={Address}/>
                <Input onChange={({target: {value}}) => onChange(id, "Login", value)} addonBefore={"Воркер"}
                       value={Login}/>
                <Input onChange={({target: {value}}) => onChange(id, "Password", value)}
                       style={{maxWidth: matches ? "none" : 220}}
                       addonBefore={"Пароль"} value={Password}/>
                <DeleteOutlined className={"desktopDelete"} onClick={onDelete} style={{color: "#ef5250"}}/>
            </li>
        );
    }
);

const Pools = forwardRef(({children, ...props}: any, ref: any) => {
    return (
        <ul ref={ref} className="comments">
            {children}
        </ul>
    );
});

const DesktopPoolsPage = () => {
    const pools = useAppSelector((state) => state.page.pools);
    const dispatch = useAppDispatch();
    const matches = useMediaQuery(`(${mediumSize})`)

    useEffect(() => {
        dispatch(getPoolsAction())
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(getPoolsAction())
        }, 1500)
        return () => {
            clearInterval(interval)
        }
    }, []);

    const [createFormRef] = Form.useForm();

    const [editablePools, setEditablePools] = useState<any>([]);
    const [modalVisible, setModalVisible] = useState(false);

    const changeParam = (id: any, paramName: any, paramValue: any) => {


        const _arr = [...editablePools.filter((el: any) => el.id !== id)]

        _arr.splice(editablePools.findIndex((el: any) => el.id === id), 0, {
            ...editablePools.find((el: any) => el.id === id),
            [paramName]: paramValue
        });


        setEditablePools(_arr)
    }

    const createPoolForm = (onFinish?: (fields: PoolType) => void, ref?: any) => <Form initialValues={{
        Type: ['btc']
    }} form={ref} onFinish={onFinish} layout='horizontal'>
        <Form.Item rules={[{
            required: true,
            message: "Поле «Адрес» является обязательным."
        }]} name={"Address"}>
            <Input placeholder={"Адрес"}/>
        </Form.Item>
        <Form.Item name={"Login"}>
            <Input placeholder={"Логин"}/>
        </Form.Item>
        <Form.Item name={"Password"}>
            <Input placeholder={"Пароль"}/>
        </Form.Item>
        {/*<Form.Header>Type</Form.Header>*/}
        <Form.Item rules={[{
            required: true,
            message: "Поле «Тип» является обязательным."
        }]} name={"Type"}>
            <Selector
                columns={3}
                options={[
                    {label: 'btc', value: 'btc'},
                    {label: 'kas', value: 'kas'},
                    {label: 'ltc', value: 'ltc'},
                ]}
            />
        </Form.Item>
    </Form>

    useEffect(() => {
        setEditablePools(pools.map((el, index) => ({...el, id: index + 1})));
    }, [JSON.stringify(pools)]);

    const dragEnded = (param: any) => {
        const {source, destination} = param;

        // Check if destination exists before proceeding
        if (!destination) {
            return;
        }

        let _arr = [...editablePools];
        // Extract the source item from the list
        const _item = _arr.splice(source.index, 1)[0];
        // Insert it at the destination index
        _arr.splice(destination.index, 0, _item);
        setEditablePools(_arr);
    };

    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    return (
        <StyledTest>
            <Modal
                closable={false}
                getContainer={() => document.querySelector(`.${StyledTest.styledComponentId}`)!}
                open={modalVisible}
                onClose={() => {
                    setModalVisible(false)
                }}
                okText={"Добавить"}
                onOk={() => createFormRef.submit()}
                cancelText={"Отмена"}
                onCancel={() => setModalVisible(false)}
                cancelButtonProps={{style: {background: "#2c3139", color: "#9297a0", border: 'none'}}}
                okButtonProps={{style: {background: "#0d2d36", border: '1px solid #26a59a'}}}
            >
                {createPoolForm((fields) => {
                    dispatch(changePoolsAction([...pools, {
                        ...fields,
                        Type: fields.Type?.[0]
                    } as PoolType])).unwrap().then(() => {
                        createFormRef.resetFields();
                        setModalVisible(false)
                    }).finally(() => {
                        return Promise.resolve(true)
                    })
                }, createFormRef)
                }</Modal>

            <h1>Список пулов</h1>
            <div className={"dnd-wrap"}>
                <Top>
                    <div className={"info"}><InfoCircleFilled/>Приоритет пулов управляется очередностью в списке,
                        методом перетаскивания
                    </div>
                    <div className="actions">
                        <Button onClick={() => setModalVisible(true)}>Добавить пул</Button>
                        <Button loading={buttonLoading} onClick={async () => {
                            setButtonLoading(true)
                            await dispatch(changePoolsAction(editablePools)).unwrap().finally(() => {
                                setButtonLoading(false)
                                return Promise.resolve(true)
                            })
                        }}>Сохранить</Button>
                    </div>
                </Top>

                {/* rendering comments */}
                <DragDropContext onDragEnd={dragEnded}>
                    <Droppable droppableId="comments-wrapper">
                        {(provided: any, snapshot: any) => (
                            <Pools ref={provided.innerRef} {...provided.droppableProps}>
                                {editablePools.map((_pool: any, index: any) => {
                                    return (
                                        <Draggable
                                            draggableId={`comment-${_pool.id}`}
                                            index={index}
                                            key={_pool.id}
                                        >
                                            {(_provided: any, _snapshot: any) => (
                                                <Pool
                                                    onDelete={() => {
                                                        Modal.confirm({
                                                            title: `Подтвердить действие для ${editablePools?.[index]?.Address}`,
                                                            content: 'Вы уверены, что хотите удалить этот пул?',
                                                            getContainer: () => document.querySelector(`.${StyledTest.styledComponentId}`)!,
                                                            okText: "Удалить",
                                                            cancelButtonProps: {
                                                                style: {
                                                                    background: "#2c3139",
                                                                    color: "#9297a0",
                                                                    border: 'none'
                                                                }
                                                            },
                                                            okButtonProps: {
                                                                style: {
                                                                    background: "#0d2d36",
                                                                    border: '1px solid #26a59a'
                                                                }
                                                            },
                                                            cancelText: "Отмена",
                                                            onOk: async () => await dispatch(changePoolsAction(editablePools.filter((el: any, index_: any) => index_ !== index))).unwrap().finally(() => {
                                                                return Promise.resolve(true)
                                                            })
                                                        })

                                                    }}
                                                    matches={matches}
                                                    onChange={changeParam}
                                                    ref={_provided.innerRef}
                                                    dragHandleProps={_provided.dragHandleProps}
                                                    {..._provided.draggableProps}
                                                    snapshot={_snapshot}
                                                    {..._pool}
                                                />
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </Pools>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </StyledTest>
    );
};

export default DesktopPoolsPage;
