import React, {useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {ArrowUpOutlined} from "@ant-design/icons";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {getRateAction, getTimedStatusAction} from "../../redux/pageSlice";
import toPercentage from "../../utils/toPercentage";
import CustomLineChart from "../../components/desktop/CustomLineChart";
import {mediumSize, mobileSize} from "../../config";
import splitNumberAndUnit from "../../utils/splitNumberAndUnit";
import FanSVG from "../../images/FanSVG";
import {RateType} from "../../utils/types";
import StyledSelect from "../../components/desktop/StyledSelect";

const Top = styled.div`
    display: flex;
    gap: 16px;
    margin-bottom: 38px;
    width: 100%;
`;

const Bottom = styled.div`
    display: flex;
    width: 100%;
    gap: 16px;
`;

const DNSWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;


    > span {
        font-size: 14px;
        margin-right: auto;
        color: #d7d9df;
        display: flex;
        justify-content: space-between;
        gap: 24px;
        width: 100%;

        span {
            &:last-child {
                color: #26a599;
                margin-left: auto;
                font-weight: 600;
                font-size: 14px;
            }

            &.offline {
                color: #ff514f;
            }
        }
    }

`;

const SettingsWrap = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    align-items: center;
    font-weight: 600;
    gap: 10px;

    .left {
        display: flex;
        width: 100%;
        gap: 10px;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 2px solid #373c43;
        text-align: left;

        .left-item {
            display: flex;
            flex-direction: column;

            &:last-child {
                text-align: right;
            }
        }

        h2 {
            margin-bottom: 0;
            font-weight: 600;
            color: #d7d9df;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            position: relative;

            svg {
                position: absolute;
                right: -40px;
                bottom: -25px;
            }
        }

        span {
            color: #26a599;
            font-size: 18px;

            &.off {
                color: #ff514f;
            }
        }
    }

    .right {
        display: flex;
        //flex-direction: column;
        //justify-content: space-between;
        width: 100%;
        gap: 10px;
        align-items: center;

        .right-item {
            display: flex;
            flex-direction: column;
            gap: 10px;
            //border-left: 2px solid #373c43;
            //padding-left: 14px;
            font-size: 10px;
            color: #b5b7ba;

            &:first-child {
                margin-right: auto;
            }

            &:last-child {
                text-align: right;
            }

            span {
                font-size: 15px;
                color: #26a599;

                &.off {
                    color: #ff514f;
                }
            }
        }
    }
`;

const PoolsWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    .pool-item {
        display: grid;
        grid-template-columns: 40px 1fr;
        justify-content: space-between;
        //grid-template-columns: repeat(auto-fit, minmax(65px, .5fr));
        //gap: ;
        align-items: center;
        padding-bottom: 10px;
        color: #c6c6c6;
        font-size: 14px;
        font-weight: 600;

        .ms {
            font-weight: 300;
        }

        > span {
            &:not(.ms) {
                position: relative;
                font-weight: bold;

                span {
                    bottom: -18px;
                    left: 0;
                    //right: 0;
                    color: #9199a4;
                    position: absolute;
                }
            }

            &:last-child {
                //text-align: right;
                margin-left: 8px;
            }
        }

        a {
            text-align: right;
        }

        .ant-btn {
            background: #1d2c33 !important;
            color: #00a69b !important;
            border: 1px solid #00a69b !important;
            height: auto;
            font-size: 12px;
            margin-left: auto;

            &:hover {
                opacity: .8;
            }
        }
    }
`;

const GraphWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 16px 0 16px;

    h3 {
        font-size: 15px;
        color: #d7d9df;
        margin-bottom: 20px;
    }
`;

const StyledBlockWrap = styled.div`
    display: flex;
    flex-direction: column;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    //max-width: 385px;
    width: 100%;

    .select-wrap {
        display: flex;
        width: 100%;
        margin-right: auto;

        ${StyledSelect} {

            &.ant-select {
                width: 100%;
            }
        }

        > span {
            display: flex;
            align-items: center;
            color: #9297a0;
            justify-content: center;
            font-size: 14px !important;
            border-right: none;
            border-radius: 5px 0 0 5px;
        }
    }

    &.invert {
        flex-direction: column-reverse;

        > span {
            border-radius: 6px 6px 0 0;
            border: 1px solid #2b333e;
            border-bottom: none;
            justify-content: center;
            align-items: flex-start;
            padding: 16px 0 8px 0;

        }

        > .head_title {
            border-radius: 0 0 6px 6px;
            justify-content: center;
            font-size: 14px;
            min-height: 30px;
            padding: 0 !important;
            align-items: center;
            color: #959ba5;
        }
    }

    > span {
        align-items: center;
        display: flex;
        width: 100%;
        border: 1px solid #2b333e;
        border-top: none;
        background: #252d36;
        color: #d7dadf;
        padding: 18px 16px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        flex: 1 1 auto;
    }

    .head_title {
        display: flex;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border: 1px solid #2f353b;
        font-size: 18px;
        color: #d7dadf;
        background: #1d252e;
        line-height: 18px;
        align-items: center;
        margin: 0;
        padding: 12px 16px;
        gap: 5px;

        span {
            align-items: center;
        }

        > a svg {
            transform: rotate(45deg);
            color: #6b7a89;

            &:hover {
                opacity: .8;
            }
        }
    }
`;

const StyledDesktopDashboardPage = styled.div`
    width: 100%;
    padding: 40px 11vw;
    height: fit-content;

    ${StyledSelect} {
        &.ant-select {
            padding-left: 5px;
            box-shadow: none !important;
            width: auto;

            .ant-select-selector {
                height: auto;
                box-shadow: none !important;
                background: none;
                border: none;
                width: auto !important;

                .ant-select-selection-item {
                    padding-left: 5px;
                    color: #d19a66 !important;
                    font-weight: 600;
                }
            }
        }
    }

    .graph_range {
        display: flex;
        width: 100%;
        padding: 0 24px;
        font-weight: 600;
        gap: 10px;

        > ${StyledSelect} {

            &.ant-select {
                flex: 1 0 auto;

                .ant-select-selector {
                    padding: 0 !important;
                }
            }
        }

        .actions {
            display: flex;
            height: 30px;
            gap: 10px;

            span {
                cursor: pointer;

                &:hover {
                    opacity: .8;
                }

                &:not(:last-child) {
                    padding-right: 10px;
                    border-right: 1px solid #373d48;
                }

                &.active {
                    color: #d19a66;
                }
            }
        }
    }

    .container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr auto 0.6fr 1.2fr 1.2fr;
        gap: 16px 16px;
        grid-auto-flow: row;
        grid-template-areas:
    "hash hash hash pools pools pools settings settings settings dns dns dns"
    "header header header header header header header header header header header header"
    "stat_btc stat_btc stat_ltc stat_ltc workers workers stat_custom_btc stat_custom_btc stat_custom_ltc stat_custom_ltc custom_devfee custom_devfee"
    "btc_hash_graph btc_hash_graph btc_hash_graph btc_hash_graph btc_hash_graph btc_hash_graph ltc_hash_graph ltc_hash_graph ltc_hash_graph ltc_hash_graph ltc_hash_graph ltc_hash_graph"
    "btc_my_hash_graph btc_my_hash_graph btc_my_hash_graph btc_my_hash_graph btc_my_hash_graph btc_my_hash_graph ltc_my_hash_graph ltc_my_hash_graph ltc_my_hash_graph ltc_my_hash_graph ltc_my_hash_graph ltc_my_hash_graph";

        > div {
            display: flex;
        }


        .pools {
            grid-area: pools;
        }

        .header {
            grid-area: header;
        }

        .settings {
            grid-area: settings;
        }

        .dns {
            grid-area: dns;
        }

        .hash {
            grid-area: hash;
        }

        .ltc_hash_graph {
            grid-area: ltc_hash_graph;
        }

        .btc_my_hash_graph {
            grid-area: btc_my_hash_graph;
        }

        .ltc_my_hash_graph {
            grid-area: ltc_my_hash_graph;
        }

        .btc_hash_graph {
            grid-area: btc_hash_graph;
        }

        .stat_btc {
            grid-area: stat_btc;
        }

        .stat_ltc {
            grid-area: stat_ltc;
        }

        .workers {
            grid-area: workers;
        }

        .stat_custom_btc {
            grid-area: stat_custom_btc;
        }

        .stat_custom_ltc {
            grid-area: stat_custom_ltc;
        }

        .workers {
            grid-area: workers;
        }

        .custom_devfee {
            grid-area: custom_devfee;
        }
    }


    //display: flex;
    //flex-direction: column;
    //

    .header {
        font-size: 20px;
        color: #d7dbe0;
        margin-top: 16px;
    }

    @media (${mediumSize}) {
        padding: 40px 6vw;
        ${StyledBlockWrap} {
            & > span {
                padding: 18px 36px;
            }

            & > .head_title {

                padding: 12px 36px;
            }
        }

        ${GraphWrap} {
            padding: 0 0px;
        }

        .container {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-rows: repeat(9, auto);
            gap: 16px 16px;
            grid-auto-flow: row;
            grid-template-areas:
    "hash hash pools pools"
    "settings settings dns dns"
    "header header header header"
    "stat_btc stat_ltc stat_custom_btc stat_custom_ltc"
    "workers workers custom_devfee custom_devfee"
    "btc_hash_graph btc_hash_graph btc_hash_graph btc_hash_graph"
    "btc_my_hash_graph btc_my_hash_graph btc_my_hash_graph btc_my_hash_graph"
    "ltc_hash_graph ltc_hash_graph ltc_hash_graph ltc_hash_graph"
    "ltc_my_hash_graph ltc_my_hash_graph ltc_my_hash_graph ltc_my_hash_graph";
        }
    }
    @media (${mobileSize}) {
        padding: 40px 6vw;
        ${StyledBlockWrap} {
            > span {
                padding: 18px 36px;
            }
        }

        .nopadding .invert > span {
            padding: 18px 16px;
        }

        ${GraphWrap} {
            padding: 0 0;
        }

        .pools {
            display: none !important;
        }

        .container {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: repeat(9, auto);
            gap: 16px 16px;
            grid-auto-flow: row;
            grid-template-areas:
    "hash hash"
    //"pools pools"
    "settings settings"
    "dns dns"
    "header header"
    "stat_btc stat_custom_btc"
    "stat_ltc stat_custom_ltc"
    "workers custom_devfee"
    "btc_hash_graph btc_hash_graph"
    "btc_my_hash_graph btc_my_hash_graph"
    "ltc_hash_graph ltc_hash_graph"
    "ltc_my_hash_graph ltc_my_hash_graph";
        }
    }
`;


const StateWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;


    span {
        text-align: center;
        position: relative;

        &:nth-child(1) {
            font-size: 18px;
            color: #d7d9df;

            .anticon {
                position: absolute;
                right: -25px;

                svg {
                    transform: rotate(45deg);
                    color: #6b7a89;

                    &:hover {
                        opacity: .8;
                    }
                }
            }
        }

        &:nth-child(2) {
            font-size: 20px;
            color: #26A599;
        }

        &:nth-child(3) {
            font-size: 11px;
            color: #9DA3AD;
        }
    }
`;

const HashRateWrap = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 16px;
    gap: 20px;
    width: 100%;

    .hash-rate-item {
        display: flex;
        flex-direction: column;
        gap: 10px;

        > span {
            font-weight: 600;

            &:first-child {
                color: #d7dadf;

                > span {
                    &:first-child {
                        color: #00a69b;
                    }

                    &:last-child {
                        margin-left: 5px;
                        font-size: 12px;
                    }
                }
            }

            &:last-child {
                font-size: 10px;
                color: #6f757f;
            }
        }
    }
`;

function BlockWrap({title, children, link, invert, ...rest}: any) {
    const title_ = <div className={"head_title"}>{title} {link && <Link to={link}><ArrowUpOutlined/></Link>}</div>;

    return (
        <StyledBlockWrap {...rest} className={invert ? "invert" : ""}>
            {title_}
            <span>
                {children}
            </span>
        </StyledBlockWrap>
    );
}

function DesktopDashboardPage() {
    const dispatch = useAppDispatch();
    const status = useAppSelector((state) => state.page.status);
    const timed_status = useAppSelector((state) => state.page.timed_status);
    const rate = useAppSelector((state) => state.page.rate);
    const user = useAppSelector((state) => state.page.user);

    const firstItem = useMemo(() => {
        return status?.[0]
    }, [status])

    const first24TimedItem = useMemo(() => {
        // return {...(timed_status["24hours"]?.[0]), RealPercent: .05}
        return timed_status["24hours"]?.[0];
    }, [timed_status])

    useEffect(() => {
        dispatch(getTimedStatusAction("24hours"));
    }, []);


    const getRate = (time: string, type: "btc" | "ltc", selectedPool: string) => {

        type SmallRateType = {
            Time: RateType["Time"], Hash: RateType["Hash"]
            MyHash: RateType["MyHash"]
        }

        const rawData = rate?.[time]?.[type];
        if (!rawData)
            return []

        const mergedResult: SmallRateType[] = (selectedPool === "__All__" ? Object.keys(rawData!) : [selectedPool])
            .reduce((acc: RateType[], pool) => {
                return [...acc, ...rawData[pool]]
            }, [])
            .reduce((acc: any, item) => {
                const {Time, Hash, MyHash} = item;

                if (!acc[Time]) {
                    acc[Time] = {
                        Time,
                        Hash: 0,
                        MyHash: 0
                    };
                }
                acc[Time].Hash += Hash;
                acc[Time].MyHash += MyHash;
                return acc
            }, {});


        // const data = [...rawData?.[Object.keys(rawData)?.[0]] || []];

        const data = Object.values(mergedResult) as RateType[] || [];
        // const data = [
        //     {
        //         "Time": "2024-12-21T09:34:00Z",
        //         "Hash": 160906376399.83408,
        //         "HashString": "160.91Gh/s",
        //         "MyHash": 0,
        //         "MyHashString": "0.00h/s"
        //     },
        //     {
        //         "Time": "2024-12-21T23:36:00Z",
        //         "Hash": 180906376399.83408,
        //         "HashString": "180.91Gh/s",
        //         "MyHash": 5,
        //         "MyHashString": "5.00h/s"
        //     }
        // ];

        // Преобразуем массив в Map для быстрого доступа к данным по времени
        const dataMap = new Map(data.map(item => [item.Time, item]));

        // Получаем самый ранний и самый поздний временной штамп
        const times: any = data.map(item => new Date(item.Time));
        const minTime = new Date(Math.min(...times));
        const maxTime = new Date(Math.max(...times));

        // Функция для добавления минут к дате
        function addMinutes(date: any, minutes: any) {
            return new Date(date.getTime() + minutes * 60000);
        }

        // Функция для форматирования времени без миллисекунд
        function formatTimeWithoutMilliseconds(date: any) {
            return date.toISOString().replace('.000', '');
        }

        // Формируем массив с интервалами по минутам
        const result = [];
        let currentTime = minTime;
        while (currentTime <= maxTime) {
            const isoTime = formatTimeWithoutMilliseconds(currentTime);
            result.push({
                Time: isoTime,
                Hash: dataMap.get(isoTime)?.Hash || 0,
                HashString: dataMap.get(isoTime)?.HashString || "0.00h/s",
                MyHash: dataMap.get(isoTime)?.MyHash || 0,
                MyHashString: dataMap.get(isoTime)?.MyHashString || "0.00h/s"
            });
            currentTime = addMinutes(currentTime, 1);
        }
        return result;
    }

    const [btcGraphTime, setBtcGraphTime] = useState<"1hour" | "12hours" | "24hours">("24hours")

    const availableBtcPools = useMemo(() => {
        // console.log("availableBtcPools", Object.keys(rate?.[btcGraphTime]?.["btc"] || {}));
        return Object.keys(rate?.[btcGraphTime]?.["btc"] || {}) || []
    }, [btcGraphTime, rate]);

    const availableLtcPools = useMemo(() => {
        return Object.keys(rate?.[btcGraphTime]?.["ltc"] || {})
    }, [btcGraphTime, rate]);

    type selectedPoolType = {
        btc: "__All__" | string,
        ltc: "__All__" | string
    }

    const [selectedPool, setSelectedPool] = useState<selectedPoolType>({
        btc: "__All__",
        ltc: "__All__",
    })

    const btcGraphValue = useMemo(() => getRate(btcGraphTime, "btc", selectedPool.btc), [btcGraphTime, rate, selectedPool.btc]);
    const ltcGraphValue = useMemo(() => getRate(btcGraphTime, "ltc", selectedPool.ltc), [btcGraphTime, rate, selectedPool.ltc]);

    useEffect(() => {
        dispatch(getRateAction(btcGraphTime));
    }, [btcGraphTime]);


    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(getRateAction(btcGraphTime))
        }, 60 * 1000)
        return () => {
            clearInterval(interval)
        }
    }, []);

    const getName = (value: number): string => {
        switch (value) {
            case 0:
                return "Admin";
            case 1:
                return "Off";
            case 2:
                return "DevFee";
            case 3:
                return "MyFee";
            case 4:
                return "DevFee&MyFee";
            default:
                return "Unknown";
        }
    }

    const formatHashrateSpan = (hash: number): any => {
        const units = ["", "K", "M", "G", "T", "P"];
        let hashrate = hash;

        let unitIndex = 0;
        while (hashrate >= 1000 && unitIndex < units.length - 1) {
            hashrate /= 1000;
            unitIndex++;
        }

        return <><span>{hashrate?.toFixed(2)}</span> <span>{units[unitIndex]}h/s</span></>;
    }
    return (
        <StyledDesktopDashboardPage>
            <div className="container">
                <div className="header">
                    <span>Статистика хостинга</span>
                </div>
                <div className="pools">
                    <BlockWrap title={"Пулы"} link={"/pools"}>
                        <PoolsWrap>
                            {user?.Pools?.map((pool, index) => <div key={index} className="pool-item">
                                <span>{pool.Type}:</span>
                                <span>{pool.Address}
                                    <span>{Array.from({length: pool?.Password?.length}).map(el => "*")}</span>
                                </span>
                                {/*<span className="ms">90ms</span>*/}
                                {/*<Link to={"/pools"}><Button>Изменить</Button></Link>*/}
                            </div>)}
                            {/*<div className="pool-item">*/}
                            {/*    BTC: <span>btc.f2pool.com:3333 <span>*****</span></span> <span*/}
                            {/*    className="ms">90ms</span>*/}
                            {/*    <Button>Изменить</Button>*/}
                            {/*</div>*/}
                            {/*<div className="pool-item">*/}
                            {/*    LTC: <span>ltc.com:3333 <span>*****</span></span> <span className="ms">90ms</span>*/}
                            {/*    <Button>Изменить</Button>*/}
                            {/*</div>*/}
                        </PoolsWrap>
                    </BlockWrap>
                </div>
                <div className="settings">
                    <BlockWrap title={"Настройки"} link={firstItem?.Name ? `/settings/${firstItem?.Name}` : "/"}>
                        <SettingsWrap>
                            <div className="left">
                                <div className="left-item">
                                    <h2>Режим работы</h2>
                                    <span>{getName(firstItem?.Mode)}</span>
                                </div>
                                <div className="left-item">
                                    MyFee <span
                                    className={[3, 4].includes(firstItem?.Mode) ? "" : "off"}>{[3, 4].includes(firstItem?.Mode) ? toPercentage(firstItem?.Percent) : "-"}</span>

                                </div>
                            </div>
                            <div className="right">
                                <div className="right-item">DevFee
                                    Block <span
                                        className={[3, 4].includes(firstItem?.Mode) ? "" : "off"}>{[3, 4].includes(firstItem?.Mode) ? "ON" : "OFF"}</span>
                                </div>
                                <FanSVG $speed={firstItem?.RealPercent * 100}
                                        className={(firstItem?.RealPercent >= 0.0001) ? "" : "stop"}/>
                                <div className="right-item">
                                    24 часа<span
                                    className={(first24TimedItem?.RealPercent >= 0.0001) ? "" : "off"}>{(first24TimedItem?.RealPercent >= 0.0001) ? toPercentage(first24TimedItem?.RealPercent) : "-"}</span>
                                </div>
                            </div>
                        </SettingsWrap>
                    </BlockWrap></div>
                <div className="dns">
                    <BlockWrap title={"DNS сервера"}>
                        <DNSWrap>
                            {firstItem?.Servers?.map((el, index) => (
                                <span key={index}><span>DNS{index + 1}: {el.Addr}</span> <span
                                    className={!el.Online ? "offline" : ""}>{el.Online ? "Online" : "Offline"}</span></span>))}
                        </DNSWrap>
                    </BlockWrap>
                </div>
                <div className="hash">
                    <BlockWrap title={"Ваш хешрейт"}>
                        <HashRateWrap>
                            <div className="hash-rate-item">
                                <span>BTC: {formatHashrateSpan(firstItem?.Normals?.MyBTCHash)}</span>
                                <span>Средний за 24 часа: {first24TimedItem?.Normals?.MyBTCHashString}</span>
                            </div>

                            <div className="hash-rate-item">
                                <span>LTC: {formatHashrateSpan(firstItem?.Normals?.MyLTCHash)}</span>
                                <span>Средний за 24 часа: {first24TimedItem?.Normals?.MyLTCHashString}</span>
                            </div>

                            {/*<div className="hash-rate-item">*/}
                            {/*    <span>LTC: <span>929</span> <span>TH/s</span></span>*/}
                            {/*    <span>Средний за 24 часа: 9269 ph/s</span>*/}
                            {/*</div>*/}
                        </HashRateWrap>
                    </BlockWrap></div>
                <div className="ltc_hash_graph">
                    <BlockWrap invert
                               title={<span className={"graph_range"}><div className={"select-wrap"}>
                                   <span>Пул</span>
                                   <StyledSelect suffixIcon={null} onSelect={(value) => {
                                       setSelectedPool({...selectedPool, ltc: value as string})
                                   }} value={selectedPool.ltc} options={[{
                                       label: "Все",
                                       value: "__All__"
                                   }, ...availableLtcPools?.map((el) => ({
                                       label: el,
                                       value: el
                                   }))]}/>
                               </div>
                                   <StyledSelect onChange={(value) => setBtcGraphTime(value as any)}
                                                 dropdownStyle={{width: "max-content"}} suffixIcon={null}
                                                 value={btcGraphTime} options={[{
                                       label: "1ч",
                                       value: "1hour"
                                   }, {
                                       label: "12ч",
                                       value: "12hours"
                                   }, {
                                       label: "24ч",
                                       value: "24hours"
                                   }]}/>

                                   {/*<span className="actions">*/}
                                   {/*    <span className={btcGraphTime === "1hour" ? "active" : ""}*/}
                                   {/*          onClick={() => setBtcGraphTime("1hour")}>1ч</span>*/}
                                   {/*<span className={btcGraphTime === "12hours" ? "active" : ""}*/}
                                   {/*      onClick={() => setBtcGraphTime("12hours")}>12ч</span>*/}
                                   {/*    <span className={btcGraphTime === "24hours" ? "active" : ""}*/}
                                   {/*          onClick={() => setBtcGraphTime("24hours")}>24ч</span></span>*/}

                    </span>}>
                        <GraphWrap>
                            <h3>Хешрейт LTC</h3>
                            <CustomLineChart data={ltcGraphValue}/>
                        </GraphWrap>
                    </BlockWrap></div>
                <div className="btc_my_hash_graph">
                    <BlockWrap invert
                               title={<span className={"graph_range"}><div className={"select-wrap"}>
                                   <span>Пул</span><StyledSelect suffixIcon={null} onSelect={(value) => {
                                   setSelectedPool({...selectedPool, btc: value as string})
                               }} value={selectedPool.btc} options={[{
                                   label: "Все",
                                   value: "__All__"
                               }, ...availableBtcPools?.map((el) => ({
                                   label: el,
                                   value: el
                               }))]}/></div>
                                   <StyledSelect onChange={(value) => setBtcGraphTime(value as any)}
                                                 dropdownStyle={{width: "max-content"}} suffixIcon={null}
                                                 value={btcGraphTime} options={[{
                                       label: "1ч",
                                       value: "1hour"
                                   }, {
                                       label: "12ч",
                                       value: "12hours"
                                   }, {
                                       label: "24ч",
                                       value: "24hours"
                                   }]}/>


                                   {/*<span className="actions">*/}
                                   {/*    <span className={btcGraphTime === "1hour" ? "active" : ""}*/}
                                   {/*          onClick={() => setBtcGraphTime("1hour")}>1ч</span>*/}
                                   {/*<span className={btcGraphTime === "12hours" ? "active" : ""}*/}
                                   {/*      onClick={() => setBtcGraphTime("12hours")}>12ч</span>*/}
                                   {/*    <span className={btcGraphTime === "24hours" ? "active" : ""}*/}
                                   {/*          onClick={() => setBtcGraphTime("24hours")}>24ч</span></span>*/}
                    </span>}>
                        <GraphWrap>
                            <h3>Ваш Хешрейт BTC</h3>
                            <CustomLineChart data={btcGraphValue} my/>
                        </GraphWrap>
                    </BlockWrap></div>
                <div className="ltc_my_hash_graph">
                    <BlockWrap invert
                               title={<span className={"graph_range"}><div className={"select-wrap"}>
                                   <span>Пул</span><StyledSelect suffixIcon={null} onSelect={(value) => {
                                   setSelectedPool({...selectedPool, ltc: value as string})
                               }} value={selectedPool.ltc} options={[{
                                   label: "Все",
                                   value: "__All__"
                               }, ...availableLtcPools?.map((el) => ({
                                   label: el,
                                   value: el
                               }))]}/></div>
                                   <StyledSelect onChange={(value) => setBtcGraphTime(value as any)}
                                                 dropdownStyle={{width: "max-content"}} suffixIcon={null}
                                                 value={btcGraphTime} options={[{
                                       label: "1ч",
                                       value: "1hour"
                                   }, {
                                       label: "12ч",
                                       value: "12hours"
                                   }, {
                                       label: "24ч",
                                       value: "24hours"
                                   }]}/>

                                   {/*<span className="actions">*/}
                                   {/*    <span className={btcGraphTime === "1hour" ? "active" : ""}*/}
                                   {/*          onClick={() => setBtcGraphTime("1hour")}>1ч</span>*/}
                                   {/*<span className={btcGraphTime === "12hours" ? "active" : ""}*/}
                                   {/*      onClick={() => setBtcGraphTime("12hours")}>12ч</span>*/}
                                   {/*    <span className={btcGraphTime === "24hours" ? "active" : ""}*/}
                                   {/*          onClick={() => setBtcGraphTime("24hours")}>24ч</span></span>*/}
                    </span>}>
                        <GraphWrap>
                            <h3>Ваш Хешрейт LTC</h3>
                            <CustomLineChart data={ltcGraphValue} my/>
                        </GraphWrap>
                    </BlockWrap></div>
                <div className="btc_hash_graph">
                    <BlockWrap invert
                               title={<span className={"graph_range"}>
                        <div className={"select-wrap"}>
                            <span>Пул</span><StyledSelect suffixIcon={null} onSelect={(value) => {
                            setSelectedPool({...selectedPool, btc: value as string})
                        }} value={selectedPool.btc} options={[{
                            label: "Все",
                            value: "__All__"
                        }, ...availableBtcPools?.map((el) => ({
                            label: el,
                            value: el
                        }))]}/></div>
                        <StyledSelect onChange={(value) => setBtcGraphTime(value as any)}
                                      dropdownStyle={{width: "max-content"}} suffixIcon={null} value={btcGraphTime}
                                      options={[{
                                          label: "1ч",
                                          value: "1hour"
                                      }, {
                                          label: "12ч",
                                          value: "12hours"
                                      }, {
                                          label: "24ч",
                                          value: "24hours"
                                      }]}/>

                                   {/*<span className="actions">*/}
                                   {/*    <span className={btcGraphTime === "1hour" ? "active" : ""}*/}
                                   {/*          onClick={() => setBtcGraphTime("1hour")}>1ч</span>*/}
                                   {/*<span className={btcGraphTime === "12hours" ? "active" : ""}*/}
                                   {/*      onClick={() => setBtcGraphTime("12hours")}>12ч</span>*/}
                                   {/*    <span className={btcGraphTime === "24hours" ? "active" : ""}*/}
                                   {/*          onClick={() => setBtcGraphTime("24hours")}>24ч</span></span>*/}
                    </span>}>
                        <GraphWrap>
                            <h3>Хешрейт BTC</h3>
                            <CustomLineChart data={btcGraphValue}/>
                        </GraphWrap>
                    </BlockWrap>
                </div>
                <div className="stat_btc nopadding">
                    <BlockWrap invert title={splitNumberAndUnit(firstItem?.Normals?.BTCHashString).unit}>
                        <StateWrap>
                            <span>Хэшрейт BTC</span>
                            <span>{splitNumberAndUnit(firstItem?.Normals?.BTCHashString).number}</span>
                            <span>Средний за 24 часа: {first24TimedItem?.Normals?.BTCHashString}</span>
                        </StateWrap>
                    </BlockWrap>
                </div>
                <div className="stat_ltc nopadding">
                    <BlockWrap invert title={splitNumberAndUnit(firstItem?.Normals?.LTCHashString).unit}>
                        <StateWrap>
                            <span>Хэшрейт LTC</span>
                            <span>{splitNumberAndUnit(firstItem?.Normals?.LTCHashString).number}</span>
                            <span>Средний за 24 часа: {first24TimedItem?.Normals?.LTCHashString}</span>
                        </StateWrap>
                    </BlockWrap>
                </div>
                <div className="stat_custom_btc nopadding">
                    <BlockWrap invert title={splitNumberAndUnit(firstItem?.Customs?.BTCHashString).unit}>
                        <StateWrap>
                            <span>Custom BTC</span>
                            <span>{splitNumberAndUnit(firstItem?.Customs?.BTCHashString).number}</span>
                            <span>Средний за 24 часа: {first24TimedItem?.Customs?.BTCHashString}</span>
                        </StateWrap>
                    </BlockWrap>
                </div>
                <div className="stat_custom_ltc nopadding">
                    <BlockWrap invert title={splitNumberAndUnit(firstItem?.Customs?.LTCHashString).unit}>
                        <StateWrap>
                            <span>Custom LTC</span>
                            <span>{splitNumberAndUnit(firstItem?.Customs?.LTCHashString).number}</span>
                            <span>Средний за 24 часа: {first24TimedItem?.Customs?.LTCHashString}</span>
                        </StateWrap>
                    </BlockWrap>
                </div>
                <div className="workers nopadding">
                    <BlockWrap invert title={"pcs"}>
                        <StateWrap>
                            <span>Воркеров <Link to={"/workers"}><ArrowUpOutlined/></Link></span>
                            <span>{firstItem?.Normals?.Count}</span>
                            <span>Сейчас онлайн</span>
                        </StateWrap>
                    </BlockWrap>
                </div>
                <div className="custom_devfee nopadding">
                    <BlockWrap invert title={"pcs"}>
                        <StateWrap>
                            <span>Custom Воркеров</span>
                            <span>{firstItem?.Customs?.Count}</span>
                            <span>Сейчас онлайн</span>
                        </StateWrap>
                    </BlockWrap>
                </div>
            </div>


        </StyledDesktopDashboardPage>
    )
        ;
}

export default DesktopDashboardPage;