import React, {SVGProps} from 'react';
import styled, {keyframes} from "styled-components";

const fanAnimation = keyframes` 0% {
                                    transform: rotate(0deg)
                                }

                                    100% {
                                        transform: rotate(360deg)
                                    }

`
export const StyledFanSVG = styled.svg<{ $speed: number }>`
    height: 40px;
    width: 40px;

    path {
        fill: #26a59a;
        stroke: none;
    }

    animation: ${fanAnimation} ${({$speed}) => `${$speed < 1 ? 5 : $speed >= 10 ? 1 : 2.5}s`} infinite linear;

    &.stop {
        animation: none !important;

        path {
            fill: #ff514f !important;
        }
    }
`;

function FanSVG({$speed, ...props}: SVGProps<SVGSVGElement> & { $speed: number }) {
    return (
        <StyledFanSVG $speed={$speed} {...props} height="100px" width="100px" version="1.1" id="_x32_"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512.00 512.00" xmlSpace="preserve"
                      fill="#000000"
                      transform="rotate(0)matrix(1, 0, 0, 1, 0, 0)" stroke="#000000">
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <g>
                    <path className={"st0"}
                          d="M176.713,229.639c5.603-16.892,16.465-31.389,30.628-41.571c-14.778-34.253-22.268-74.165-20.636-112.788 c0.217-5.095-4.279-13.455-15.648-8.54c-22.522,9.728-42.142,24.48-59.949,40.872c-17.008,15.667-20.853,40.637-7.96,56.168 C124.507,189.491,149.096,213.274,176.713,229.639z"></path>
                    <path className={"st0"}
                          d="M290.516,179.908c22.286-29.938,53.094-56.375,87.366-74.264c4.534-2.367,9.52-10.436-0.435-17.843 c-19.674-14.634-42.268-24.253-65.352-31.47c-22.086-6.909-45.623,2.249-52.623,21.198c-11.605,31.334-19.892,64.536-20.254,96.632 C256.644,170.561,274.614,172.728,290.516,179.908z"></path>
                    <path className={"st0"}
                          d="M412.281,169.754c-32.949,5.63-65.842,15.041-93.822,30.772c11.841,13.3,18.949,29.956,20.69,47.319 c37.064,4.324,75.362,17.798,107.983,38.524c4.316,2.738,13.799,3.029,15.232-9.302c2.847-24.354-0.108-48.724-5.403-72.334 C451.884,182.157,432.191,166.345,412.281,169.754z"></path>
                    <path className={"st0"}
                          d="M335.287,282.361c-5.603,16.881-16.464,31.38-30.627,41.56c14.779,34.254,22.267,74.165,20.635,112.789 c-0.217,5.095,4.28,13.455,15.667,8.54c22.504-9.729,42.142-24.48,59.93-40.872c17.008-15.667,20.853-40.637,7.96-56.168 C387.511,322.508,362.904,298.717,335.287,282.361z"></path>
                    <path className={"st0"}
                          d="M221.501,332.091c-22.267,29.93-53.075,56.367-87.348,74.264c-4.533,2.358-9.519,10.427,0.435,17.834 c19.675,14.634,42.269,24.253,65.352,31.471c22.086,6.908,45.623-2.249,52.623-21.198c11.605-31.334,19.892-64.527,20.254-96.632 C255.392,341.43,237.404,339.263,221.501,332.091z"></path>
                    <path className={"st0"}
                          d="M172.85,264.146c-37.064-4.326-75.362-17.798-107.982-38.525c-4.316-2.738-13.8-3.028-15.233,9.303 c-2.846,24.352,0.109,48.724,5.422,72.333c5.059,22.576,24.752,38.388,44.663,34.979c32.948-5.631,65.842-15.042,93.82-30.772 C181.699,298.164,174.591,281.509,172.85,264.146z"></path>
                    <path className={"st0"}
                          d="M255.991,195.503c-33.402,0-60.475,27.091-60.475,60.492c0,33.411,27.073,60.493,60.475,60.493 c33.419,0,60.51-27.082,60.51-60.493C316.502,222.594,289.411,195.503,255.991,195.503z"></path>
                </g>
            </g>
        </StyledFanSVG>
    );
}

export default FanSVG;