import React, {useEffect, useState} from 'react';
import './App.css';
import History from "./utils/history";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "./redux/hooks";
import {clearUserAction, getUserAction} from "./redux/pageSlice";
import styled from "styled-components";
import AuthPage from "./pages/AuthPage";
import MainLayout from "./components/MainLayout";
import PoolsPage from "./pages/PoolsPage";
import NewDashboardPage from "./pages/NewDashboardPage";
import SettingsPage from "./pages/SettingsPage";
import ExceptionsPage from "./pages/ExceptionsPage";
import DesktopAuthPage from "./pages/desktop/DesktopAuthPage";
import DesktopDashboardPage from "./pages/desktop/DesktopDashboardPage";
import DesktopLayout from "./components/desktop/DesktopLayout";
import WorkersPage from "./pages/desktop/WorkersPage";
import DesktopSettingsPage from "./pages/desktop/DesktopSettingsPage";
import DesktopPoolsPage from "./pages/desktop/DesktopPoolsPage";
import {useMediaQuery} from "usehooks-ts";
import axios from "axios";

const StyledApp = styled.div`
    display: flex;
    flex-direction: column;
`;

function App() {
    const user = useAppSelector((state) => state.page.user);
    const loading = useAppSelector((state) => state.page.loading);

    const location = useLocation();
    History.navigate = useNavigate();
    History.location = location;

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getUserAction())

        axios.interceptors.response.use(response => response, error => {
            const statusCode = error.response ? error.response.status : null;
            if (statusCode === 401) {
                dispatch(clearUserAction())
                History.push("/");
            }
            throw error;
        })
    }, []);

    const [isDesktop, setIsDesktop] = useState<boolean>(true)
    const matches = useMediaQuery('(min-width: 1440px)')

    useEffect(() => {
        // if (!matches) {
        //     setIsDesktop(false)
        // } else {
        //     setIsDesktop(true)
        // }
    }, [matches]);
    return (
        <StyledApp>
            <Routes>
                {loading ? <Route path={"*"} element={
                    <DesktopLayout noHeader>
                        Загрузка...
                    </DesktopLayout>
                }></Route> : user?.Login ? (isDesktop ? <>
                    <Route path={"/"} element={<DesktopLayout>
                        <DesktopDashboardPage/>
                    </DesktopLayout>}/>
                    <Route path={"/workers"} element={<DesktopLayout>
                        <WorkersPage/>
                    </DesktopLayout>}/>
                    <Route path={"/settings/:name"} element={<DesktopLayout>
                        <DesktopSettingsPage/>
                    </DesktopLayout>}/>
                    <Route path={"/exceptions/:name"} element={<DesktopLayout>
                        <DesktopSettingsPage/>
                    </DesktopLayout>}/>
                    <Route path={"/pools"} element={<DesktopLayout>
                        <DesktopPoolsPage/>
                    </DesktopLayout>}/>
                </> : <>
                    <Route path={"/"} element={<MainLayout>
                        <NewDashboardPage/>
                    </MainLayout>}/>
                    <Route path={"/settings/:name"} element={<MainLayout>
                        <SettingsPage/>
                    </MainLayout>}/>
                    <Route path={"/pools"} element={<MainLayout>
                        <PoolsPage/>
                    </MainLayout>}/>
                    <Route path={"/exceptions/:name"} element={<MainLayout>
                        <ExceptionsPage/>
                    </MainLayout>}/>
                    <Route path={"*"} element={<MainLayout>

                    </MainLayout>}/>
                    {/*<Route path={"/project/new"} element={<DashboardLayout>*/}
                    {/*  <NewProjectPage/>*/}
                    {/*</DashboardLayout>}/>*/}
                    {/*<Route path={"/"} element={<DashboardLayout>*/}
                    {/*  <CurrentProjectsPage/>*/}
                    {/*</DashboardLayout>}/>*/}
                    {/*<Route path={"/tariffs"} element={<DashboardLayout>*/}
                    {/*  <TariffsPage/>*/}
                    {/*</DashboardLayout>}/>*/}
                    {/*<Route path={"/integrations"} element={<DashboardLayout>*/}
                    {/*  <IntegrationsPage/>*/}
                    {/*</DashboardLayout>}/>*/}
                    {/*<Route path={"/database"} element={<DashboardLayout>*/}
                    {/*  <DataBasePage/>*/}
                    {/*</DashboardLayout>}/>*/}
                    {/*<Route path={"/account"} element={<DashboardLayout>*/}
                    {/*  <AccountPage/>*/}
                    {/*</DashboardLayout>}/>*/}
                    {/*<Route path={"/exit"} element={<DashboardLayout>*/}
                    {/*  <ExitPage/>*/}
                    {/*</DashboardLayout>}/>*/}
                    {/*<Route path={"/projects"} element={<DashboardLayout>*/}
                    {/*  <OurProjectsPage/>*/}
                    {/*</DashboardLayout>}/>*/}
                </>) : <>
                    {isDesktop ? <Route path={"*"} element={<DesktopAuthPage/>}/> :
                        <Route path={"*"} element={<AuthPage/>}/>}
                </>}
            </Routes>
        </StyledApp>
    );
}

export default App;
