import React, {useMemo} from 'react';
import styled from "styled-components";
import LogoHalfSVG from "../../images/LogoHalfSVG";
import {Link, useLocation} from "react-router-dom";
import {api, mediumSize, mobileSize} from "../../config";
import {useAppSelector} from "../../redux/hooks";
import {MenuOutlined} from "@ant-design/icons";
import {Popover} from 'antd-mobile';
import History from "../../utils/history";

const StyledDesktopHeader = styled.div`
    display: flex;
    background: #232a33;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 11vw;
    border-bottom: 1px solid #313a47;

    .scrollbarIsActive {
        padding: 10px calc(11vw + 10px) 10px 11vw;
    }

    .adm-popover .adm-popover-arrow {
        color: #1d252e;
    }

    .adm-popover-inner {
        background-color: #1d252e !important;

        .adm-popover-menu-item {
            background-color: #1d252e !important;

        }

        .adm-popover-menu-item-text {
            border-color: #313a47;
        }

        a {
            text-decoration: none;
            color: #cdcdcd !important;
        }
    }

    > .anticon {
        display: none;
    }

    > .anticon svg {
        color: #b4b6bb;
        height: 20px;
        width: 20px;
        flex: 0 0 auto;
        cursor: pointer;
    }

    .nav-items {
        display: flex;
        gap: 36px;


        > a {
            display: flex;
            align-items: center;
            color: #cdcdcd;
            text-decoration: none;
            font-size: 16px;
            line-height: 16px;
            font-weight: 600;
            transition: all 0s;

            &:hover {
                opacity: .8;
            }

            &.active {
                color: #26a59a;
            }
        }

        .user-wrap {
            display: flex;
            background: #1d252e;
            color: #ffffff;
            align-items: center;
            border-radius: 6px;
            border: 1px solid #2d3137;
            font-size: 14px;
            height: 29px;
            margin-left: 14px;

            > span {
                padding: 0 16px 0 26px;
            }


            a {
                background: #192026;
                border-left: 1px solid #2d3137;
                border-radius: 6px;
                color: #959ca5;
                height: 100%;
                font-size: 14px;
                text-decoration: none;
                display: flex;
                align-items: center;
                padding: 0 12px;

                &:hover {
                    opacity: .8;
                }

                span {
                    line-height: 9px;
                }
            }
        }
    }

    .logo-wrap {
        display: flex;

        &:hover {
            opacity: .8;
        }

        svg {
            height: 40px;
            width: 125px;
        }
    }

    @media (${mediumSize}) {
        padding: 10px 6vw;

        &.scrollbarIsActive {
            padding: 10px calc(6vw + 10px) 10px 6vw;
        }

        .logo-wrap {
            margin-right: 10px;
        }

        .nav-items {
            gap: 24px;

            .user-wrap {
                //margin: 0;
            }

            a {
                font-size: 14px;
            }
        }
    }

    @media (${mobileSize}) {
        padding: 10px 6vw;
        .nav-items {
            display: none;
        }

        > .anticon {
            display: inline-block;
        }
    }
`;

function DesktopHeader({...props}: any) {
    const user = useAppSelector((state) => state.page.user);
    const location = useLocation()
    const {pathname} = location
    const status = useAppSelector((state) => state.page.status);
    const firstItem = useMemo(() => {
        return status[0]
    }, [status])

    const actions: any[] = useMemo(() => {
        return [
            {key: '1', link: "/", text: <Link to={"/"}>Главная</Link>},
            {key: '2', link: "/pools", text: <Link to={"/pools"}>Мои пулы</Link>},
            {key: '3', link: "/workers", text: <Link to={"/workers"}>Воркеры</Link>},
            {
                key: '4',
                link: `/settings/${firstItem?.Name}`,
                text: <Link to={`/settings/${firstItem?.Name}`}>Настройки</Link>
            },
            {key: '5', link: "/exit", text: <a href={`${api}/exit`}>Выход</a>},
        ]
    }, [firstItem]);

    return (
        <StyledDesktopHeader {...props}>
            <Link to={"/"}>
                <span className="logo-wrap">
                    <LogoHalfSVG/>
                </span>
            </Link>
            <div className="nav-items">
                <Link to={"/"} className={pathname === "/" ? "active" : ""}>Главная</Link>
                <Link to={"/pools"} className={pathname.includes("/pools") ? "active" : ""}>Мои пулы</Link>
                <Link to={"/workers"} className={pathname.includes("/workers") ? "active" : ""}>Воркеры</Link>
                <Link to={`/settings/${firstItem?.Name}`}
                      className={pathname.includes("/settings") ? "active" : ""}>Настройки</Link>
                {/*<Link to={"/"}>Ru</Link>*/}
                <div className="user-wrap">
                    <span>{user?.Login}</span>
                    <a href={`${api}/exit`}><span>Выход</span></a>
                </div>
            </div>
            <Popover.Menu
                getContainer={() => document.querySelector(`.${StyledDesktopHeader.styledComponentId}`)!}
                actions={actions.map(action => ({
                    ...action,
                    icon: null,
                }))}
                onAction={(node: any) => node.link === "/exit" ? window.location.href = `${api}/exit` : History.push(node.link)}
                placement='bottomRight'
                trigger='click'
            >
                <MenuOutlined/>
            </Popover.Menu>
        </StyledDesktopHeader>
    );
}

export default DesktopHeader;