import React, {useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import {Input, Table} from "antd";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {getWorkersAction} from "../../redux/pageSlice";
import {WorkerType} from "../../utils/types";
import {ColumnsType} from "antd/es/table";
import moment from "moment";
import toPercentage from "../../utils/toPercentage";
import {mediumSize, mobileSize} from "../../config";

const WorkersWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px 20px;
    background: #232a33;
    border-radius: 3px;
    border: 1px solid #2b353b;
    flex: 1 1 auto;

    .ant-pagination-item-link {
        &:not(:disabled) {
            svg {
                fill: #fff;
            }
        }

        &:disabled {
            svg {
                fill: #373d48;
            }
        }
    }

    .ant-pagination-item {
        background: #0d2d36 !important;
        border-radius: 5px;
        border-color: #26a59a !important;

        a {
            color: rgba(255, 255, 255, 0.66) !important;
            line-height: 16px;
            font-size: 16px;
        }

        padding: 10px;
        height: 36px;
    }

    .ant-pagination-item-active {
        a {
            color: rgb(255, 255, 255) !important;
        }
    }

    .workers-top {
        display: flex;
        align-items: center;
        margin-bottom: 25px;

        .ant-btn {
            background: #0d2d36 !important;
            border-radius: 5px;
            border-color: #26a59a !important;
            color: #ffffff !important;
            padding: 10px 46px;
            line-height: 16px;
            font-size: 16px;
            height: 36px;

            &:hover {
                opacity: .8;
            }
        }

        .ant-input {
            max-width: 490px;
            margin-right: 15px;
            background: #162026;
            padding-top: 10px;
            font-size: 16px;
            line-height: 16px;
            padding-bottom: 10px;
            color: #a0a0a0 !important;
            border-color: #313a3f;
            font-weight: 600;
            height: 36px;

            &::placeholder {
                color: #44535d !important;
                font-weight: 600;
                line-height: 16px;
                font-size: 16px;
            }
        }

        p {
            margin-left: auto;
            color: #9da5b0;
            font-size: 16px;
            text-align: right;
            margin-bottom: 0;
        }
    }
`;

const StyledWorkersPage = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 11vw;
    width: 100%;

    .ant-table-column-sorter-up, .ant-table-column-sorter-down {
        &.active {
            color: #26a59a;
        }
    }

    .ant-pagination {

        .ant-pagination-item {
            padding: 0;
            align-items: center;
            display: flex;
            justify-content: center;
        }
    }

    h1 {
        font-weight: 600;
        font-size: 21px;
        color: #cdcdcd;
        margin-bottom: 18px;
    }

    .ant-table {
        //background: #1c1c1c !important; /* Цвет фона таблицы */
        color: #fff; /* Цвет текста */
        background: transparent !important;

        * {
            border-color: #373d48 !important;
        }
    }

    .ant-table-thead > tr > th {
        background: #192026 !important;
        color: #ffffff !important;
        font-weight: bold;
        padding-top: 14px;
        padding-bottom: 14px;
        font-size: 14px;
        line-height: 14px;
        text-align: left;

        &:not(:last-child) {
            border-right: none !important;
        }
    }

    .ant-table-tbody > tr > td {
        background: #1b232a !important;
        color: #e0e0e0;
        padding-top: 14px;
        padding-bottom: 14px;
        text-align: left;
        font-size: 14px;
        line-height: 14px;

        &:not(:last-child) {
            border-right: none !important;

        }
    }

    .ant-table-tbody > tr {
        &:nth-child(even) {
            td {
                background: #192026 !important;
            }
        }
    }

    @media (${mediumSize}) {
        padding: 24px 6vw;

        .ant-pagination-next, .ant-pagination-prev {
            display: none;
        }

        .workers-top {
            flex-direction: column;
            gap: 20px;
            align-items: flex-start;

            input {
                max-width: none !important;
                width: 100%;
            }

            p {
                text-align: left;
                margin-left: 0;
            }

            .ant-input {
                margin: 0;
            }
        }
    }
    //.ant-table-tbody > tr:hover > td {
    //    background: #333333; /* Цвет при наведении */
    //}
`;
const formatMinutesWithMoment = (minutes: number) => {
    const duration = moment.duration(minutes, 'minutes');
    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const mins = duration.minutes();
    return (days > 0 ? `${days}d ` : '') +
        (hours > 0 ? `${hours}h ` : '') +
        (mins > 0 ? `${mins}m ` : '');
}

function WorkersPage() {
    const workers = useAppSelector((state) => state.page.workers);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getWorkersAction());
    }, []);


    const [searchInputValue, setSearchInputValue] = useState<string>("");

    function escapeRegex(str: string): string {
        return str.replace(/[-\/\\^$+?.()|[\]{}]/g, '\\$&');
    }

    const filteredWorkers = useMemo(() => {
        if (searchInputValue.includes("*")) {
            // Преобразуем маску в регулярное выражение
            const regexPattern = `^${searchInputValue.split('*').map(escapeRegex).join('.*')}$`;
            const regex = new RegExp(regexPattern);

            // Фильтруем работников по имени с использованием регулярного выражения
            return workers.filter(worker => regex.test(worker.Name));
        } else if (searchInputValue === "") {
            return workers
        } else {
            return workers.filter(worker => worker.Name?.toLowerCase().includes(searchInputValue.toLowerCase()));
        }
    }, [workers, searchInputValue]);

// Колонки таблицы
    const columns: ColumnsType<WorkerType> = [
        {
            title: 'Worker',
            dataIndex: 'Name',
            key: 'Name',
            width: "26%",
            sorter: (a, b) => a.Name.localeCompare(b.Name),
        },
        {
            title: 'H/s',
            dataIndex: 'HashString',
            key: 'HashString',
            width: "10%",
            minWidth: 120,
            sorter: (a, b) => a.HashAvg - b.HashAvg
        },
        {
            title: 'MyFee',
            dataIndex: 'MyHashString',
            width: "10%",
            minWidth: 120,
            key: 'MyHashString',
            sorter: (a, b) => a.MyHashAvg - b.MyHashAvg,
        },
        {
            title: 'Процент',
            dataIndex: 'Percent',
            width: "14%",
            key: 'Percent',
            render: (el) => toPercentage(el),
            sorter: (a, b) => a.Percent - b.Percent,
        },
        {
            title: 'Accepted',
            dataIndex: 'Accepted',
            width: "10%",
            key: 'Accepted',
            sorter: (a, b) => a.Percent - b.Percent,
        },
        {
            title: 'Uptime',
            dataIndex: 'UptimeMin',
            width: "14%",
            key: 'UptimeMin',
            render: (el) => formatMinutesWithMoment(el) || "-",
            sorter: (a, b) => a.UptimeMin - b.UptimeMin,
        },
        {
            title: 'Pool',
            dataIndex: 'Pools',
            width: "14%",
            key: 'Pools',
            render: (el, record) => (<>{el?.[0] || ""}</>)
        },
    ];

    return (
        <StyledWorkersPage>
            <h1>Воркеры</h1>
            <WorkersWrap>
                <div className="workers-top">
                    <Input value={searchInputValue} onChange={({target: {value}}) => setSearchInputValue(value)}
                           placeholder={"Введите имя воркера или маску"}/>
                    {/*<Button>Поиск</Button>*/}
                    <p>Поддерживается маска в виде символа «*», пример: <br/>
                        Name1.worker1, Name1.*, Name*.*, *.worker1</p>
                </div>
                <Table
                    tableLayout={"fixed"}
                    columns={columns}
                    rowKey={"Name"}
                    pagination={{
                        pageSize: 15,
                        showSizeChanger: false,
                        showLessItems: true,
                        showPrevNextJumpers: false,
                        showQuickJumper: false
                    }}
                    dataSource={filteredWorkers}
                    scroll={{x: 'max-content'}}
                    // pagination={false}
                    bordered
                />
            </WorkersWrap>
        </StyledWorkersPage>
    );
}

export default WorkersPage;