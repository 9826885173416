import styled from "styled-components";
import {Select} from "antd";

const StyledSelect = styled(Select)`
    .ant-select-arrow {
        color: #313a47;
    }

    &.ant-select {
        height: auto;
        width: 100%;

        .ant-select-selector {
            font-size: 14px !important;
            background: #192026;
            border-color: #313a47;
            height: 38px;
            width: 100%;
            border-radius: 0 5px 5px 0;

            .ant-select-selection-item {
                color: #cdcdcd !important;
            }
        }
    }

    .ant-select-dropdown {
        background: #252d36;

        .ant-select-item-option-selected {
            background: #192026;
        }

        .ant-select-item-option-content {
            color: #9297a0;
        }
    }
`;
export default StyledSelect
