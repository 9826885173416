const formatHashrate = (hashrate: number): string => {
    const units = ["", "K", "M", "G", "T", "P"];

    if (!hashrate) {
        return `0 h/s`;
    }

    let unitIndex = 0;
    while (hashrate >= 1000 && unitIndex < units.length - 1) {
        hashrate /= 1000;
        unitIndex++;
    }

    return `${hashrate.toFixed(2)} ${units[unitIndex]}h/s`;
}
export default formatHashrate