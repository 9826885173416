import React, {useEffect} from 'react';
import styled from "styled-components";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {getStatusAction} from "../redux/pageSlice";
import {Button, Space} from "antd";
import {Link} from "react-router-dom";
import toPercentage from "../utils/toPercentage";

const StyledDashboardPage = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 20px;

`;

const StatusCard = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    padding: 20px;
    background: #fafafa;

    h1 {
        margin-bottom: 0;
    }

    strong {
        font-weight: 600;

        &.green {
            color: #00aa3c;
        }

        &.red {
            color: #ff5b18;
        }
    }
`;

const StatusWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;
    padding: 0 15px 50px 15px;

    >h1 {
        text-align: center;
        padding-top: 100px;
        color: #fafafa;
    }
    table {
        margin-top: 10px;
        font-size: 14px;

        tr {
            border-top: 1px solid #2ba79d;


            td {
                padding: 5px 10px;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                    text-align: right;
                }

                &:nth-child(2) {
                    text-align: center;
                }
            }
        }
    }
`;


function NewDashboardPage() {

    const dispatch = useAppDispatch();
    const status = useAppSelector((state) => state.page.status);

    useEffect(() => {
        dispatch(getStatusAction());
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(getStatusAction())
        }, 1500)
        return () => {
            clearInterval(interval)
        }
    }, []);

    const getName = (value: number): string => {
        switch (value) {
            case 0:
                return "Admin";
            case 1:
                return "Off";
            case 2:
                return "DevFee";
            case 3:
                return "MyFee";
            case 4:
                return "DevFee&MyFee";
            default:
                return "Unknown";
        }
    }

    return (
        <StyledDashboardPage>
            <StatusWrap>
                {!status?.length ? <h1>Wait to assign a proxy.</h1> : status?.map((el: any) => <StatusCard>
                    <Space size={20} direction={"vertical"}>
                        <h1><strong>{el.Name}</strong></h1>
                        <Space style={{width: "100%", justifyContent: "space-between"}}>
                            <Space direction={"vertical"}>
                                <span><strong>Workers:</strong> {el.Normals.Count} pcs</span>
                                <span>BTC: ~ <strong
                                    className={"green"}>{el.Normals.BTCHashString}</strong></span>
                                <span>LTC: ~ <strong
                                    className={"green"}>{el.Normals.LTCHashString}</strong></span>
                                <span>KAS: ~ <strong
                                    className={"green"}>{el.Normals.KASHashString}</strong></span>
                            </Space>
                            <Space direction={"vertical"}>
                                <span><strong>Customs:</strong> {el.Customs.Count} pcs</span>
                                <span>BTC: ~ <strong
                                    className={"red"}>{el.Customs.BTCHashString}</strong></span>
                                <span>LTC: ~ <strong
                                    className={"red"}>{el.Customs.LTCHashString}</strong></span>
                                <span>KAS: ~ <strong
                                    className={"red"}>{el.Customs.KASHashString}</strong></span>
                            </Space>
                        </Space>
                        <Space style={{width: "100%", justifyContent: "space-between"}}>
                            <Space direction={"vertical"}>
                                <span><strong>Mode:</strong> {getName(el.Mode)}</span>
                                <span><strong>MyFee:</strong> {[3, 4].includes(el.Mode) ?
                                    <strong className={"green"}>{toPercentage(el.Percent)}</strong> : "Off"}</span>
                            </Space>
                            <Link to={`/settings/${el.Name}`}>
                                <Button>SETTINGS</Button>
                            </Link>
                        </Space>
                    </Space>

                </StatusCard>)}
                {/*{status.map(el => <StatusCard>*/}
                {/*    <span className="top">*/}
                {/*        <Space direction={"vertical"}>*/}
                {/*            <span className={`note ${!el.Enabled ? "disabled" : ""}`}>{el.Note}*/}
                {/*                {el.Online && <span className="version"> ({el.Version})</span>}*/}
                {/*            </span>*/}
                {/*            <span className="serial"> {el.Serial}</span>*/}
                {/*        </Space>*/}
                {/*        <Space style={{textAlign: "right"}} direction={"vertical"}>*/}
                {/*        <span className={`online ${el.Online ? "" : "red"}`}>{el.Online ? "Online" : "Offline"}</span>*/}
                {/*            /!*{el.Online && <span className="version">{el.Version}</span>}*!/*/}
                {/*        </Space>*/}
                {/*    </span>*/}
                {/*    <table>*/}
                {/*        <tbody>*/}
                {/*        {el?.Pools?.map(pool => <tr>*/}
                {/*            <td>*/}
                {/*                <Space size={5} direction={"vertical"}>*/}
                {/*                    {pool.Login}*/}
                {/*                    <span className={"address"}>{pool.Address}</span>*/}
                {/*                </Space>*/}
                {/*            </td>*/}
                {/*            <td>*/}
                {/*                <Space size={2} direction={"vertical"}>*/}
                {/*                    <span>{pool.CurFee}%</span>*/}
                {/*                    <span className={"avgFee"}>({pool.AvgFee}%)</span>*/}
                {/*                </Space>*/}
                {/*            </td>*/}
                {/*            <td>*/}
                {/*                <Space size={5} direction={"vertical"}>*/}
                {/*                    <span className={"lastAccepted"}*/}
                {/*                          style={{whiteSpace: "nowrap"}}>{moment(pool.LastAccepted).format("DD.MM.YYYY HH:mm")}</span>*/}
                {/*                    <Space className={"acceptedHash"} size={5}>*/}
                {/*                        <span>{pool.AcceptedHash?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}Mh/s</span>*/}
                {/*                        <span>({pool.AcceptedCount})</span>*/}
                {/*                    </Space>*/}
                {/*                </Space>*/}
                {/*            </td>*/}
                {/*        </tr>)}*/}
                {/*        </tbody>*/}
                {/*    </table>*/}
                {/*    /!*<PoolsWrap>*!/*/}
                {/*    /!*    {el?.Pools?.map(pool => <PoolBlock>*!/*/}
                {/*    /!*        <Space direction={"vertical"}>*!/*/}
                {/*    /!*            {pool.Login}*!/*/}
                {/*    /!*            {pool.Address}*!/*/}
                {/*    /!*        </Space>*!/*/}
                {/*    /!*        <Space direction={"vertical"}>*!/*/}
                {/*    /!*            {pool.CurFee}*!/*/}
                {/*    /!*            {pool.AvgFee}*!/*/}
                {/*    /!*        </Space>*!/*/}
                {/*    /!*        <Space direction={"vertical"}>*!/*/}
                {/*    /!*            <span*!/*/}
                {/*    /!*                style={{whiteSpace: "nowrap"}}>{moment(pool.LastAccepted).format("DD.MM.YYYY HH:mm")}</span>*!/*/}
                {/*    /!*            <Space size={5}>*!/*/}
                {/*    /!*                {pool.AcceptedHash}*!/*/}
                {/*    /!*                ({pool.AcceptedCount})*!/*/}
                {/*    /!*            </Space>*!/*/}
                {/*    /!*        </Space>*!/*/}
                {/*    /!*    </PoolBlock>)}*!/*/}
                {/*    /!*</PoolsWrap>*!/*/}
                {/*</StatusCard>)}*/}
            </StatusWrap>
        </StyledDashboardPage>
    );
}

export default NewDashboardPage;