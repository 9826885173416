import React, {useEffect, useMemo, useRef, useState} from 'react';
import styled from "styled-components";
import {Button, Form, Input, InputNumber, Space, Switch, Table} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {
    changeExceptionAction,
    changePasswordAction,
    editModeAction,
    editPercentAction,
    getStatusAction
} from "../../redux/pageSlice";
import {Form as MobileForm, Modal} from "antd-mobile";
import {StatusType} from "../../utils/types";
import toPercentage from "../../utils/toPercentage";
import {mediumSize, mobileSize} from "../../config";
import {useMediaQuery} from "usehooks-ts";

const Top = styled.div`
    display: flex;
    gap: 30px;
    border: 1px solid #2b353b;
    padding: 18px 44px;
    background: #232a33;
    border-radius: 3px;

    button {
        display: flex;
        position: relative;
        background: transparent;
        border: none;
        color: #d8dbe1;
        font-weight: 600;
        font-size: 14px;
        align-items: center;

        &.active {
            &::after {
                position: absolute;
                content: "";
                height: 3px;
                width: 100%;
                background: #00a69b;
                bottom: -18px;
                left: 0;
            }
        }
    }
`;

const PasswordWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px 40px;
    background: #232a33;
    border: 1px solid #2b353b;
    margin-top: 20px;
    border-radius: 3px;

    .ant-form-item-control-input-content {
        display: flex;
        align-items: flex-end;
    }

    .ant-input {
        max-width: 490px;
        margin-right: 15px;
        background: #162026;
        padding-top: 10px;
        font-size: 14px;
        line-height: 14px;
        padding-bottom: 10px;
        color: #a0a0a0 !important;
        border-color: #313a3f;
        font-weight: 600;
        height: 32px;

        &::placeholder {
            color: #44535d !important;
            font-weight: 600;
            line-height: 14px;
            font-size: 14px;
        }
    }

    h3 {
        font-size: 16px;
        color: #d5d5d5;
        font-weight: 600;
        margin-bottom: 38px;
    }

    .ant-form-item {
        margin-bottom: 0;
    }

    .ant-form {
        .ant-col {
            padding: 0 !important;
        }

        label {
            padding-bottom: 18px;
            font-size: 12px;
            color: #d5d5d5;
        }
    }
`;

const ExceptionWrap = styled.div`
    display: flex;
    flex-direction: column;
    background: #232a33;
    padding: 30px 40px;
    border: 1px solid #2b353b;
    margin-top: 20px;
    border-radius: 3px;

    h3 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 26px;
        color: #ffffff;
    }

    > div {
        display: flex;
        border-radius: 3px;
        gap: 30px;
        width: 100%;
    }


    .exception-right {
        width: 100%;
        color: #9ea4ae;
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;

        > span {
            font-size: 16px;
        }
    }

    .ant-table-wrapper {
        width: 100%;
    }

    .ant-table {
        flex: 1 1 auto;
        //background: #1c1c1c !important; /* Цвет фона таблицы */
        color: #fff; /* Цвет текста */
        background: transparent !important;

        * {
            border-color: #373d48 !important;
        }
    }

    .ant-table-thead > tr > th {
        background: #192026 !important;
        color: #ffffff;
        font-weight: bold;
        padding-top: 14px;
        padding-bottom: 14px;
        height: 38px;
        font-size: 14px;
        line-height: 14px;
        text-align: left;
        border-top: 1px solid #373d48;

        &:first-child {
            border-left: 1px solid #373d48;
        }

        &:not(:last-child) {
            border-right: none !important;
        }
    }

    .ant-table-container {
        border: none !important;
    }

    .ant-table-tbody > tr > td {
        background: #1b232a !important;
        color: #e0e0e0;
        padding-top: 0;
        padding-bottom: 0;
        text-align: left;
        font-size: 14px;
        height: 38px;
        line-height: 14px;

        &:not(:last-child) {
            border-right: none !important;

        }

        &:first-child {
            border-left: 1px solid #373d48;
        }
    }

    .ant-table-tbody > tr {
        &:last-child {
            td {
                &:first-of-type {
                    border-bottom-left-radius: 8px;
                }

                &:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
        }

        &:nth-child(even) {
            td {
                background: #192026 !important;
            }
        }
    }
`;

const ModeWrap = styled.div`
    display: flex;
    flex-direction: column;
    background: #232a33;
    padding: 30px 40px;
    margin-top: 20px;
    border: 1px solid #2b353b;
    border-radius: 3px;

    .mode-wrap-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 24px;
    }

    h2 {
        font-size: 18px;
        color: #d5d5d5;
        margin: 0;

        span {
            font-size: 16px;
            color: #26a59a;
        }
    }

    .mode-items {
        display: flex;
        flex-direction: column;
    }

    .mode-item {
        display: flex;
        background: #1c252b;
        color: #d8dbe1;
        border-radius: 3px;
        border: 1px solid #313a3f;

        &:first-of-type {
            margin-bottom: 16px;
        }

        .ant-switch {
            margin-right: 20px;
        }

        .ant-input-number-group-addon {
            border-color: #313a3f;
            color: #a0a0a0 !important;
        }

        .ant-input-number {

            .ant-input-number-input {
                color: #a0a0a0 !important;
            }
        }

        .ant-input, .ant-input-number {
            margin-left: 15px;
            background: #162026;
            font-size: 16px;
            line-height: 16px;
            color: #a0a0a0 !important;
            border-color: #313a3f;
            font-weight: 600;
            height: 36px;

            &::placeholder {
                color: #44535d !important;
                font-weight: 600;
                line-height: 16px;
                font-size: 16px;
            }
        }

        .mode-top {
            display: flex;
            align-items: center;
            font-size: 18px;
            padding: 20px 20px 20px 20px;
            border-right: 1px solid #313a3f;
            font-weight: 600;
            max-width: 260px;
            flex: 1 0 auto;
            width: 100%;
        }

        > span {
            align-items: center;
            font-size: 14px;
            color: #ababab;
            padding: 14px 20px;
            display: flex;
            line-height: 22px;
        }
    }
`;

const StyledDesktopSettingsPage = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px 11vw;
    height: fit-content;

    @media (${mediumSize}) {
        padding: 24px 6vw;

        .mode-item {
            flex-direction: column;

            .mode-top {
                border-right: none;
            }
        }

        ${Top} {
            padding: 18px 20px;
        }

        ${ModeWrap} {
            padding: 30px 20px;
        }

        ${PasswordWrap} {
            padding: 30px 20px;

            .ant-form {
                .ant-form-item {
                    width: 100% !important;
                    margin: 0 !important;

                    input {
                        margin: 0;
                        max-width: none;
                    }
                }
            }

            .ant-form-item-control-input-content {
                flex-direction: column;
                gap: 20px;

                label {
                    padding-bottom: 10px;
                }
            }
        }

        ${ExceptionWrap} {
            padding: 30px 20px;

            > div {
                flex-direction: column-reverse;

                .exception-right {
                    flex-direction: row;
                    align-items: flex-end;
                    justify-content: space-between;
                    position: relative;

                    .ant-btn {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                    }
                }
            }
        }
    }

    .adm-modal-body {
        background: #192026 !important;
        color: #ababab !important;

        .adm-list-item-content {
            border: none;
        }

        .adm-list-body, .adm-list-item {
            background: transparent;
        }

        .ant-input, .ant-input-number {
            max-width: 490px;
            background: #162026;
            padding-top: 10px;
            font-size: 14px;
            line-height: 14px;
            padding-bottom: 10px;
            color: #a0a0a0 !important;
            border-color: #313a3f;
            font-weight: 600;
            height: 32px;

            &::placeholder {
                color: #44535d !important;
                font-weight: 600;
                line-height: 14px;
                font-size: 14px;
            }
        }

        .ant-input-number {
            padding: 0;

            .ant-input-number-input {
                color: #a0a0a0 !important;
            }
        }

        .adm-modal-content {
            color: #ababab;
        }

        .adm-modal-button {
            color: #00a69b !important;
            height: auto;
            font-size: 14px;
        }

        .adm-modal-button-primary {
            background: #1d2c33 !important;
            color: #00a69b !important;
            border: 1px solid #00a69b !important;
            height: auto;
            font-size: 14px;
        }
    }

    .ant-btn {
        background: #1d2c33 !important;
        color: #00a69b !important;
        border: 1px solid #00a69b !important;
        height: auto;
        font-size: 14px;

        &:hover {
            opacity: .8;
        }
    }

    .table-actions {
        svg {
            height: 20px;
            width: 20px;
            flex: 1 0 auto;

            path {
                fill: #00a69b;
            }
        }
    }

    h1 {
        color: #cdcdcd;
        font-size: 21px;
        font-weight: 600;
        margin-bottom: 18px;
    }
`;

function DesktopSettingsPage() {

    const formRef = useRef<any>();
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [modalVisible, setModalVisible] = useState(false)

    const [editableId, setEditableId] = useState<number | null>(null);
    const [createFormRef] = Form.useForm();
    const [editFormRef] = Form.useForm();
    const [passwordFormRef] = Form.useForm();
    const matches = useMediaQuery(`(${mediumSize})`)

    useEffect(() => {
        if (editableId === null) {
            editFormRef.resetFields();
        } else {
            setEditModalVisible(true);
            editFormRef.setFieldsValue({
                ...statusItem?.Presets[editableId],
                Percent: statusItem?.Presets[editableId].Percent * 100
            })
            console.log({
                ...statusItem?.Presets[editableId],
                Percent: statusItem?.Presets[editableId].Percent * 100
            })
        }
    }, [editableId]);

    const createExceptionForm = (onFinish?: (fields: StatusType['Presets'][number]) => void, ref?: any) => <MobileForm
        requiredMarkStyle={"none"}
        initialValues={{
            Percent: 0
        }} form={ref}
        onFinish={onFinish}
        layout='horizontal'
        mode='card'>
        <MobileForm.Item rules={[{
            required: true,
            message: "The 'Username' field is required."
        }]} name={"Username"}>
            <Input placeholder={"Worker"}/>
        </MobileForm.Item>
        <MobileForm.Item label={"MyFee"} rules={[{
            required: true,
            message: "The 'Percent' field is required."
        }]} name={"Percent"}>
            <InputNumber
                controls={false}
                formatter={(value) => `${value}%`}
                parser={(value: any) => value?.replace('%', '') as unknown as number}
                precision={2}
                // value={inputValue}
                // onChange={(value: any) => setInputValue(value || 0)}
                min={0} max={100}/>
        </MobileForm.Item>
    </MobileForm>

    const columns: any = [
        {
            title: 'Worker',
            dataIndex: 'Username',
            ellipsis: true,
            width: "100%"
        },
        // {
        //     title: 'Тип',
        //     ellipsis: true,
        //     render: (text:any, record:any, _:any, action:any) => <span>{((record.Percent || 0) * 100).toFixed(2)}%</span>,
        //     width: "30%"
        // },
        {
            title: 'Проценты',
            dataIndex: 'Percent',
            ellipsis: true,
            render: (text: any, record: any, _: any, action: any) =>
                <span>{((record.Percent || 0) * 100).toFixed(2)}%</span>,
            width: matches ? "105px" : "155px"
        },
        {
            title: '',
            width: 90,
            valueType: 'option',
            render: (text: any, record: any, _: any, action: any) => <Space className={"table-actions"}>{[
                <a
                    key="editable"
                    onClick={() => {
                        setEditableId(record.id!);
                        // setEditModalVisible(true);
                        // editFormRef.setFieldsValue({...pools[record.id!], Type: [pools[record.id!]?.Type]})
                        // editFormRef.setFieldsValue(pools[record.id!])
                        // action?.startEditable?.(record.id!);
                    }}
                >
                    <EditOutlined className={"bigSvg"}/>
                </a>,
                <a
                    key="delete"
                    onClick={async () => {
                        Modal.confirm({
                            title: 'Подтвердить действие',
                            content: 'Вы уверены, что хотите удалить это исключение?',
                            getContainer: () => document.querySelector(`.${StyledDesktopSettingsPage.styledComponentId}`)!,
                            confirmText: "Удалить",
                            cancelText: "Отмена",
                            onConfirm: async () => await dispatch(changeExceptionAction({
                                name: name!,
                                presets: statusItem?.Presets?.filter((el, index) => index !== record.id)
                            })).unwrap().finally(() => {
                                return Promise.resolve(true)
                            })
                        })

                    }}
                >
                    <DeleteOutlined className={"bigSvg"}/>
                </a>,
            ]}</Space>,
        }
    ];

    // const columns = [
    //     {
    //         title: 'Worker',
    //         dataIndex: 'worker',
    //         key: 'worker',
    //     },
    //     {
    //         title: 'Тип',
    //         dataIndex: 'type',
    //         key: 'type',
    //     },
    //     {
    //         title: 'Процент',
    //         dataIndex: 'percent',
    //         key: 'percent',
    //     },
    //     {
    //         title: '',
    //         render: (item: any, record: any) => record.worker ?
    //             <Space className={"table-actions"}><EditOutlined/> <DeleteOutlined/></Space> : ""
    //     },
    // ];

    const [currentTab, setCurrentTab] = useState<"mode" | "exceptions" | "password">("mode")

    const {name} = useParams();
    const dispatch = useAppDispatch();
    const status = useAppSelector((state) => state.page.status);
    // const isMounted = useIsMounted()

    const statusItem = useMemo(() => {
        return status.filter(el => el.Name === name)[0]
    }, [status, name]);

    const [inputValue, setInputValue] = useState<number>(0);
    const [selectedMode, setSelectedMode] = useState<0 | 1 | 2 | 3 | 4>(1);

    useEffect(() => {
        setSelectedMode(statusItem?.Mode as any)
    }, [statusItem?.Mode]);

    useEffect(() => {
        setInputValue((statusItem?.Percent || 0) * 100)
    }, [statusItem?.Percent]);

    const getName = (value: number): string => {
        switch (value) {
            case 0:
                return "Admin";
            case 1:
                return "Off";
            case 2:
                return "DevFee";
            case 3:
                return "MyFee";
            case 4:
                return "DevFee&MyFee";
            default:
                return "Unknown";
        }
    }


    return (
        <StyledDesktopSettingsPage>
            <Modal
                visible={modalVisible}
                content={createExceptionForm((fields) => {
                    dispatch(changeExceptionAction({
                        name: name!,
                        presets: [...statusItem?.Presets, {...fields, Percent: fields.Percent / 100}]
                    })).unwrap().then(() => {
                        createFormRef.resetFields();
                        setModalVisible(false)
                    }).finally(() => {
                        return Promise.resolve(true)
                    })

                    // console.log({...fields, Percent: fields.Percent / 100});
                }, createFormRef)}
                // closeOnAction
                closeOnMaskClick
                onClose={() => {
                    setModalVisible(false)
                }}
                actions={[
                    {
                        key: 'confirm',
                        primary: true,
                        style: {background: "#26a59a", border: 'none'},
                        text: 'Добавить',
                        onClick: () => createFormRef.submit()
                    },
                    {
                        key: 'cancel',
                        style: {color: "#26a59a", border: 'none'},
                        text: 'Отмена',
                        onClick: () => setModalVisible(false)
                    },
                ]}/>
            <Modal
                visible={editModalVisible}
                content={createExceptionForm((fields) => {
                    let newArray = [...statusItem?.Presets?.filter((el, index) => index !== editableId)];
                    newArray.splice(editableId!, 0, {
                        ...fields,
                        Percent: fields.Percent / 100
                    });

                    dispatch(changeExceptionAction({
                        name: name!,
                        presets: newArray
                    })).unwrap().then(() => {
                        setEditModalVisible(false)
                        setEditableId(null);
                    }).finally(() => {
                        return Promise.resolve(true)
                    })


                }, editFormRef)}
                // closeOnAction
                closeOnMaskClick
                onClose={() => {
                    setEditModalVisible(false)
                    setEditableId(null);
                }}
                actions={[
                    {
                        key: 'confirm',
                        primary: true,
                        style: {background: "#26a59a", border: 'none'},
                        text: 'Сохранить',
                        onClick: () => editFormRef.submit()
                    },
                    {
                        key: 'cancel',
                        style: {color: "#26a59a", border: 'none'},
                        text: 'Отмена',
                        onClick: () => {
                            setEditModalVisible(false)
                            setEditableId(null);
                        }
                    },
                ]}/>
            <h1>Настройки - {statusItem?.Name}</h1>
            <Top>
                <button onClick={() => setCurrentTab("mode")} className={currentTab === "mode" ? "active" : ""}>Режим
                    работы
                </button>
                <button onClick={() => setCurrentTab("exceptions")}
                        className={currentTab === "exceptions" ? "active" : ""}>Исключения
                </button>
                <button onClick={() => setCurrentTab("password")}
                        className={currentTab === "password" ? "active" : ""}>Смена пароля
                </button>
            </Top>
            {(() => {
                switch (currentTab) {
                    case "mode":
                        return <ModeWrap>
                            <div className="mode-wrap-top">
                                <h2>Текущий
                                    режим: <span>{getName(statusItem?.Mode)} {toPercentage(statusItem?.Percent)}</span>
                                </h2>
                                <Button onClick={() => {
                                    Modal.confirm({
                                        title: 'Подтвердить действие',
                                        content: 'Вы уверены, что хотите сохранить настройки?',
                                        getContainer: () => document.querySelector(`.${StyledDesktopSettingsPage.styledComponentId}`)!,
                                        confirmText: "Сохранить",
                                        cancelText: "Отмена",
                                        onConfirm: () => dispatch(editModeAction({
                                            name: statusItem?.Name,
                                            mode: selectedMode
                                        })).unwrap().then(() => {
                                            dispatch(editPercentAction({
                                                name: statusItem?.Name,
                                                percent: inputValue / 100
                                            })).unwrap().finally(() => {
                                                dispatch(getStatusAction())
                                            }).catch(({response}) => {
                                            })

                                            // if ([3, 4].includes(selectedMode)) {
                                            //     dispatch(editPercentAction({
                                            //         name: statusItem?.Name,
                                            //         percent: inputValue / 100
                                            //     })).unwrap().finally(() => {
                                            //         dispatch(getStatusAction())
                                            //     }).catch(({response}) => {
                                            //     })
                                            // } else {
                                            //     dispatch(getStatusAction())
                                            // }
                                        }).catch(({response}) => {
                                        })
                                    });
                                }}>Сохранить</Button>
                            </div>
                            <div className="mode-items">
                                <div className="mode-item">
                                    <div className="mode-top">
                                        <Switch onChange={(value) => {
                                            if (!value) {
                                                setSelectedMode(0)
                                            } else {
                                                setSelectedMode(2)
                                            }
                                        }} value={[2, 4].includes(selectedMode)}/>DevFee
                                    </div>
                                    <span>
                        Данный режим позволяет вернуть на Ваш пул все DevFee которые уходят разработчикам кастомных прошивок.
                        <br/>
                        This mode allows you to return all DevFees that go to custom firmware developers back to your pool.
                    </span>
                                </div>
                                <div className="mode-item">
                                    <div className="mode-top">
                                        <Switch onChange={(value) => {
                                            if (!value) {
                                                if (selectedMode === 4) {
                                                    setSelectedMode(2)
                                                } else {
                                                    setSelectedMode(0)
                                                }
                                            } else {
                                                setSelectedMode(4)
                                            }
                                        }}
                                                value={[4].includes(selectedMode)}/>MyFee <InputNumber
                                        disabled={selectedMode !== 4}
                                        controls={false}
                                        formatter={(value) => `${value}%`}
                                        parser={(value) => value?.replace('%', '') as unknown as number}
                                        precision={2}
                                        value={inputValue}
                                        onChange={(value: any) => setInputValue(value || 0)}
                                        min={0} max={100}/>
                                    </div>
                                    <span>
                        Данный режим позволяет задать комиссию в процентном выражении для всех мощностей.
                        <br/>
                        This mode allows you to set the commission in percentage terms for all capacities.
                    </span>
                                </div>
                            </div>
                        </ModeWrap>
                    case "exceptions":
                        return <ExceptionWrap>
                            <h3>Исключения</h3>
                            <div>
                                <Table
                                    columns={columns}
                                    pagination={false}
                                    dataSource={statusItem?.Presets?.map(((el, index) => ({...el, id: index})))}
                                    // pagination={false}
                                    rowKey={"id"}
                                    bordered
                                />
                                <div className="exception-right">
                                <span>
                                    Вы можете добавить исключения, задав каждому воркеру <br/>
                                    индивидуальный процент MyFee <br/>
                                    <br/>
                                    Поддерживается маска виде символа «*», пример: <br/>
                                    Name1.worker1, <br/>
                                    Name1.*, <br/>
                                    Name*.*, <br/>
                                    *.worker1, <br/>
                                    *.worker*foo <br/>
                                </span>
                                    <Button onClick={() => setModalVisible(true)}>Добавить</Button>
                                </div>
                            </div>
                        </ExceptionWrap>;
                    case "password":
                        return <PasswordWrap>
                            <h3>Сменить пароль доступа</h3>

                            <Form onFinish={(fields) => {
                                dispatch(changePasswordAction(fields)).unwrap().then(() => {
                                    passwordFormRef.resetFields();
                                }).catch(() => {
                                })
                            }} form={passwordFormRef} layout={"vertical"} requiredMark={false}>
                                <Form.Item style={{marginBottom: 0, flexDirection: "column"}}>
                                    <Form.Item
                                        label="Текущий пароль"
                                        name="Cur"
                                        rules={[{required: true}]}
                                        style={{display: 'inline-block', width: 'calc(40% - 24px)'}}
                                    >
                                        <Input placeholder="Текущий пароль"/>
                                    </Form.Item>
                                    <Form.Item
                                        label={"Новый пароль"}
                                        name="New"
                                        rules={[{required: true}]}
                                        style={{display: 'inline-block', width: 'calc(40% - 24px)', margin: '0 24px'}}
                                    >
                                        <Input placeholder="Новый пароль"/>
                                    </Form.Item>
                                    <Form.Item
                                        style={{
                                            display: 'inline-block',
                                            textAlign: "right",
                                            width: '20%',
                                            margin: 'auto 0 0 0'
                                        }}
                                    >
                                        <Button htmlType={"submit"}
                                                style={{height: 32, marginLeft: "auto"}}>Применить</Button>
                                    </Form.Item>
                                </Form.Item>
                            </Form>
                        </PasswordWrap>;
                }
            })()}

        </StyledDesktopSettingsPage>
    );
}

export default DesktopSettingsPage;