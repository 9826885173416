import React, {useMemo} from 'react';
import styled from "styled-components";
import moment from "moment/moment";
import formatHashrate from "../../utils/formatHashrate";
import {ChartsYAxis, LineChart} from "@mui/x-charts";
import {RateType} from "../../utils/types";
import {useMediaQuery} from "usehooks-ts";
import {mobileSize} from "../../config";
import splitNumberAndUnit from "../../utils/splitNumberAndUnit";

const StyledCustomLineChart = styled<typeof LineChart>(LineChart)`
    user-select: none;

    svg {
        text {
            fill: #d7d9df;
        }
    }
`;

type CustomLineChartTypes = {
    data: RateType[],
    my?: boolean
}

function CustomLineChart({data, my = false}: CustomLineChartTypes) {
    const matches = useMediaQuery(`(${mobileSize})`)
    const graphStyles = useMemo(() => {
        return {
            ...{
                '.MuiAreaElement-root': {
                    fill: "url(#gradientId)"
                },
                '.MuiLineElement-root': {
                    strokeWidth: 2
                },
                '.MuiChartsAxis-tickLabel': {
                    fill: "#CDCFCF !important",
                },
                '.MuiChartsAxis-directionY .MuiChartsAxis-line': {
                    display: "none"
                },
                '.MuiChartsTooltip-paper': {
                    backgroundColor: "#252d36 !important"
                },
                '.MuiChartsAxis-line': {
                    stroke: "#fff !important",
                    strokeWidth: 2
                },
                '.MuiChartsAxis-directionY .MuiChartsAxis-tick': {
                    display: "none"
                },
                '.MuiChartsAxis-tick': {
                    fill: "#d7dadf",
                    stroke: "#fff !important"
                },
                '.MuiChartsGrid-line': {
                    display: "none"
                    // strokeDasharray: 8
                }
            }, ...(matches ? {
                '.MuiChartsAxis-left .MuiChartsAxis-tickLabel': {
                    textAnchor: "middle",
                    transform: "translate(-10px)"
                },
                '.MuiChartsAxis-right .MuiChartsAxis-tickLabel': {
                    textAnchor: "middle",
                    transform: "translate(10px)"
                },
            } : {})
        }
    }, [matches]);

    // const values = Array.from({length: 24 * 60}).map((el, index) => ({
    //     "Time": moment("2024-12-21T10:05:00Z").add(index, "minutes").toISOString(),
    //     "Hash": 112298481709.51111,
    //     "HashString": "112.30Gh/s",
    //     "MyHash": 0,
    //     "MyHashString": "0.00h/s"
    // }))

    return (
        <StyledCustomLineChart
            colors={["#d19a66"]}
            margin={{
                top: matches ? 15 : 10,
                left: matches ? 40 : 90,
                right: matches ? 40 : 90,
                bottom: matches ? 25 : 20
            }}
            grid={{vertical: true}}
            xAxis={data?.length ? [{
                tickPlacement: "start",
                tickMaxStep: data?.length * 1000 * 2,
                data: data?.map(el => new Date(el.Time).valueOf()) || [],
                valueFormatter: (value) => `${moment(value).format("HH:mm")}`
            }] : undefined}
            yAxis={data?.length ? [{
                valueFormatter: (value) => data?.length ? (matches ? `${splitNumberAndUnit(formatHashrate(value)).number}${value === 0 ? "" : "\n"}${splitNumberAndUnit(formatHashrate(value)).unit}` : formatHashrate(value)) : "-",
            }] : undefined}
            tooltip={{trigger: data?.length ? "axis" : "none", classes: {valueCell: "test"}}}
            series={[
                {
                    data: data?.map(el => el?.[my ? "MyHash" : "Hash"]),
                    area: true,
                    showMark: false,
                    valueFormatter: (v, ctx) => {
                        // console.log("ctx", ctx.dataIndex);
                        return `${formatHashrate(data?.[ctx.dataIndex]?.[my ? "MyHash" : "Hash"]) || "-"}`
                    }
                },
            ]}
            sx={graphStyles}
            height={140}
        >
            <ChartsYAxis position="right"/>
            <defs>
                <linearGradient id="gradientId" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" style={{stopColor: "rgb(134,106,82)", stopOpacity: 1}}/>
                    <stop offset="100%" style={{stopColor: "rgb(41,45,52)", stopOpacity: .8}}/>
                </linearGradient>
            </defs>
        </StyledCustomLineChart>
    );
}

export default CustomLineChart;