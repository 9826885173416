import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from "./redux/store";
import en_US from "antd/locale/en_US";
import {ConfigProvider} from 'antd';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


root.render(
    <Router>
        <Provider store={store}>
            <ConfigProvider locale={en_US} theme={{
                components: {
                    Switch: {
                        /* here is your component tokens */
                        handleBg: "#152123",
                        colorPrimary: "#00a69b",
                        colorPrimaryHover: "#00a69b",
                        colorTextQuaternary: "#445964",
                        colorTextTertiary: "rgba(68,89,100,0.5)"
                    },
                },
            }}>

                <App/>
            </ConfigProvider>
        </Provider>
    </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
