const splitNumberAndUnit = (input: string) => {
    const match = input?.match(/^([\d.]+)([^\d]*)$/);
    if (match) {
        return {
            number: parseFloat(match[1]), // Числовая часть
            unit: match[2]               // Текстовая часть
        };
    }
    return {number: null, unit: null}; // Если строка не подходит
}
export default splitNumberAndUnit