import React, {useState} from 'react';
import styled from "styled-components";
import LogoHalfSVG from "../../images/LogoHalfSVG";
import {Button, Form, Input} from "antd";
import {authAction, regAction} from "../../redux/pageSlice";
import {useAppDispatch} from "../../redux/hooks";
import {mediumSize, mobileSize} from "../../config";

const FormWrap = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 396px;
    border-radius: 3px;
    background: #252d36;
    border: 1px solid #454b50;
    padding: 30px 26px 30px 26px;
    width: 100%;

    .ant-form {
        margin-bottom: 16px;

        .ant-form-item {
            margin: 0;

            &:not(:last-child) {
                margin-bottom: 9px;
            }
        }
    }

    .actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column-reverse;

        .with-lines {
            display: flex;
            align-items: center;
            margin-top: 25px;
            width: 100%;

            .line {
                display: flex;
                width: 100%;
                content: "";
                height: 1px;
                background: #454b50;
            }
        }

        a {
            //margin-left: 46px;
            //margin-left: 5px;
            text-decoration: none;
            white-space: nowrap;
            color: #ffffff;
            font-size: 16px;
            transition: all 0s;
            background: #252d36;
            position: relative;
            margin: 0 20px;
            text-align: center;
            //
            //&:after {
            //    content: "";
            //    width: 40%;
            //    display: flex;
            //    height: 1px;
            //    background: #454b50;
            //    position: absolute;
            //    top: 50%;
            //}

            &:hover {
                opacity: .8;
            }
        }

        .ant-btn {
            background: #0d2d36 !important;
            border-radius: 5px;
            border-color: #26a59a !important;
            color: #ffffff !important;
            padding: 10px 46px;
            height: auto;
            line-height: 16px;
            font-size: 16px;
            width: 100%;

            &:hover {
                opacity: .8;
            }
        }
    }

    h1 {
        color: #bcbcbc;
        font-size: 24px;
        text-align: center;
        font-weight: 600;
        margin-bottom: 25px;
    }

    .ant-input {
        background: #192026 !important;
        color: #ffffff;
        border-radius: 5px;
        padding: 12px 22px;
        line-height: 16px;
        border-color: #454b50;
        font-size: 16px;

        &::placeholder {
            color: #ffffff !important;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -webkit-background-clip: text;
            -webkit-text-stroke-color: #ffffff !important;
            -webkit-text-fill-color: #ffffff !important;
            transition: background-color 999999s ease-in-out 0s;
            box-shadow: inset 0 0 20px 20px #192026 !important;
        }
    }

    .ant-input-affix-wrapper {
        color: #ffffff;
        background: #192026 !important;
        border-radius: 5px;
        border-color: #454b50;
        font-size: 16px;
        padding: 12px 22px;
        line-height: 16px;

        input {
            background: #192026 !important;

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
                -webkit-background-clip: text;
                -webkit-text-stroke-color: #ffffff !important;
                -webkit-text-fill-color: #ffffff !important;
                transition: background-color 999999s ease-in-out 0s;
                box-shadow: inset 0 0 20px 20px #192026 !important;
            }
        }

        .ant-input-password-icon {
            svg {
                path {
                    fill: #fff;
                }
            }
        }
    }
`;

const StyledDesktopAuthPage = styled.div`
    display: flex;
    background: #192026;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100vh;
    gap: 72px;
    align-items: center;
    padding: 160px 20px 0 20px;
    font-family: Arial, sans-serif;
    overflow: auto;

    @media (${mediumSize}) {
        padding: 100px 20px 0 20px;
    }
    @media (${mobileSize}) {
        padding: 80px 20px 0 20px;
    }
    
    &::-webkit-scrollbar {
        width: 10px;
        border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: #252d36;
    }

    &::-webkit-scrollbar-track {
        background: #1d252e;
    }

    > svg {
        flex: 0 0 auto;
    }
`;

function DesktopAuthPage() {
    const dispatch = useAppDispatch();
    const [authRef] = Form.useForm();

    const [formType, setFormType] = useState<"auth" | "reg">("auth")


    return (
        <StyledDesktopAuthPage>
            <LogoHalfSVG/>
            <FormWrap>
                <h1>{formType === "auth" ? "Логин" : "Регистрация"}</h1>
                {formType === "auth" ? <Form form={authRef} onFinish={fields => dispatch(authAction(fields))}>
                    <Form.Item name={"login"}>
                        <Input placeholder={"Логин"}/>
                    </Form.Item>
                    <Form.Item name={"password"}>
                        <Input.Password placeholder={"Пароль"}/>
                    </Form.Item>
                </Form> : <Form form={authRef} onFinish={fields => dispatch(regAction(fields))}>
                    <Form.Item name={"login"} rules={[{
                        required: true,
                        message: 'Пожалуйста, введите логин!',
                    }]}>
                        <Input placeholder={"Логин"}/>
                    </Form.Item>
                    <Form.Item name={"password"} rules={[{
                        required: true,
                        message: 'Пожалуйста, введите пароль!',
                    }]}>
                        <Input.Password placeholder={"Пароль"}/>
                    </Form.Item>
                    <Form.Item name={"confirm"} dependencies={['password']}
                               hasFeedback
                               rules={[
                                   {
                                       required: true,
                                       message: 'Пожалуйста, подтвердите пароль!',
                                   },
                                   ({getFieldValue}) => ({
                                       validator(_, value) {
                                           if (!value || getFieldValue('password') === value) {
                                               return Promise.resolve();
                                           }
                                           return Promise.reject(new Error('Новый пароль, который вы ввели, не соответствует!'));
                                       },
                                   }),
                               ]}>
                        <Input.Password placeholder={"Подтвердите пароль"}/>
                    </Form.Item>
                </Form>}
                <div className="actions">
                    <div className="with-lines">
                        <span className="line"/>
                        <a href="#" onClick={() => {
                            if (formType === "auth") {
                                setFormType("reg")
                            } else {
                                setFormType("auth")
                            }
                        }}>{formType === "auth" ? "Создать аккаунт" : "Уже зарегистрированы?"}</a>
                        <span className="line"/>
                    </div>
                    <Button onClick={() => authRef.submit()}>{formType === "auth" ? "Войти" : "Регистрация"}</Button>
                </div>
            </FormWrap>
        </StyledDesktopAuthPage>
    );
}

export default DesktopAuthPage;