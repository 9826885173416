import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import DesktopHeader from "./DesktopHeader";
import {getStatusAction} from "../../redux/pageSlice";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {useLocation} from "react-router-dom";

const Content = styled.div`
    display: flex;
    flex: 1 1 auto;
    overflow: auto;

    > div {
        height: fit-content;
    }

    &::-webkit-scrollbar {
        width: 10px;
        border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: #252d36;
    }

    &::-webkit-scrollbar-track {
        background: #1d252e;
    }
`;

const StyledDesktopLayout = styled.div`
    display: flex;
    background: #192026;
    flex-direction: column;
    min-height: 100dvh;
    height: 100dvh;
    max-height: 100dvh;
    overflow: hidden;
    color: #cdcdcd;


    * {
        font-family: Arial, sans-serif;
    }
`;

function DesktopLayout({children, noHeader = false}: any) {
    const user = useAppSelector((state) => state.page.user);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (user?.Login !== undefined)
            dispatch(getStatusAction())
    }, [user]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (user?.Login !== undefined) {
                dispatch(getStatusAction())
            }
        }, 2000)
        return () => {
            clearInterval(interval)
        }
    }, [user]);

    const [isScrollbarVisible, setIsScrollbarVisible] = useState(false);

    const location = useLocation();

    useEffect(() => {
        // Функция для проверки, отображается ли скроллбар
        const checkScrollbar = () => {
            setIsScrollbarVisible((document.querySelector(`.${Content.styledComponentId}`)?.scrollHeight || 0) > window.innerHeight);
        };

        // Создаем объект ResizeObserver для отслеживания изменений размера документа
        const resizeObserver = new ResizeObserver(() => {
            checkScrollbar();
        });

        // Наблюдаем за изменениями размера элемента html (всей страницы)
        resizeObserver.observe(document.querySelector(`.${Content.styledComponentId}`)!);

        // Выполняем начальную проверку
        checkScrollbar();

        // Очищаем observer при размонтировании компонента
        return () => resizeObserver.disconnect();
    }, [location]);


    return (
        <StyledDesktopLayout>
            {!noHeader && <DesktopHeader className={isScrollbarVisible ? "scrollbarIsActive" : ""}/>}
            <Content>
                {children}
            </Content>
        </StyledDesktopLayout>
    );
}

export default DesktopLayout;