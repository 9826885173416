import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from "axios";
import {api} from "../config";
import {message} from "antd";
import History from "../utils/history";
import {PoolType, RateTimeMapType, StatusTimeMapType, StatusType, UserType, WorkerType} from "../utils/types";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)
type PageStateType = {
    user: UserType | null,
    pools: PoolType[],
    rate: RateTimeMapType,
    timed_status: StatusTimeMapType,
    workers: WorkerType[],
    status: StatusType[],
    loading: boolean
}
const initialState: PageStateType = {
    user: null,
    pools: [],
    workers: [],
    rate: {},
    status: [],
    timed_status: {},
    loading: true
}

export const authAction = createAsyncThunk(
    'page/authAction',
    async ({login, password}: {
        login: string,
        password: string
    }, {dispatch, rejectWithValue}) => {
        return await axios(`${api}/auth?a123=${login}&a321=${password}`).then(({data}) => {
            return data
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)
export const regAction = createAsyncThunk(
    'page/regAction',
    async ({login, password}: {
        login: string,
        password: string
    }, {dispatch, rejectWithValue}) => {
        return await axios(`${api}/reg`, {
            params: {
                a123: login,
                a321: password
            }
        }).then(({data}) => {
            return data
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)
// export const createTrackerAction = createAsyncThunk(
//     'page/createTrackerAction',
//     async (project: NewTrackerType, {dispatch, rejectWithValue}) => {
//         return await axios.post(`${api}/trackers`, project).then(async ({data}) => {
//             await dispatch(getTrackersAction({}))
//             return data
//         }).catch(({response}) => {
//             return rejectWithValue(response?.data || "Error")
//         })
//     }
// )
// export const editTrackerAction = createAsyncThunk(
//     'page/editTrackerAction',
//     async (project: NewTrackerType, {dispatch, getState, rejectWithValue}) => {
//         const {
//             page: {
//                 currentTrackerId
//             }
//         } = getState() as RootState;
//
//         if (currentTrackerId !== undefined)
//             return await axios.post(`${api}/trackers/${currentTrackerId}`, project).then(async ({data}) => {
//                 await dispatch(getTrackersAction({}))
//                 return data
//             }).catch(({response}) => {
//                 return rejectWithValue(response?.data || "Error")
//             })
//     }
// )
// export const changeTrackerStateAction = createAsyncThunk(
//     'page/changeTrackerStateAction',
//     async ({Id, new_state}: { Id: number, new_state: "enable" | "disable" }, {dispatch, getState, rejectWithValue}) => {
//         const {
//             page: {
//                 dateFilter,
//                 rangePickerValue
//             }
//         } = getState() as RootState;
//
//         return await axios.post(`${api}/trackers/${Id}/state/${new_state}`).then(async ({data}) => {
//             if (dateFilter !== null) {
//                 dispatch(getTrackersAction({
//                     params: {
//                         start: dateFilter[0]?.format("YYYY-MM-DD"),
//                         end: dateFilter[1]?.format("YYYY-MM-DD")
//                     }
//                 }))
//             } else if (rangePickerValue !== undefined) {
//                 dispatch(getTrackersAction({
//                     params: {
//                         start: rangePickerValue[0]?.format("YYYY-MM-DD"),
//                         end: rangePickerValue[1]?.format("YYYY-MM-DD")
//                     }
//                 }))
//             }
//
//             return data
//         }).catch(({response}) => {
//             return rejectWithValue(response?.data || "Error")
//         })
//     }
// )
export const getUserAction = createAsyncThunk(
    'page/getUserAction',
    async (_, {dispatch, rejectWithValue}) => {
        return await axios(`${api}/me`).then(({data}) => {

            return data
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)
export const getPoolsAction = createAsyncThunk(
    'page/getPoolsAction',
    async (_, {dispatch, rejectWithValue}) => {
        return await axios(`${api}/pools`).then(({data}) => {
            return data
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)

export const getRateAction = createAsyncThunk(
    'page/getRateAction',
    async (time: string, {dispatch, rejectWithValue}) => {
        return await axios(`${api}/rate/${time}`).then(({data}) => {
            return {time, data}
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)

export const getWorkersAction = createAsyncThunk(
    'page/getWorkersAction',
    async (_, {dispatch, rejectWithValue}) => {
        return await axios(`${api}/workers/12hours`).then(({data}) => {
            return data
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)
export const getStatusAction = createAsyncThunk(
    'page/getStatusAction',
    async (_, {dispatch, rejectWithValue}) => {
        return await axios(`${api}/status`).then(({data}) => {
            return data
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)
export const getTimedStatusAction = createAsyncThunk(
    'page/getTimedStatusAction',
    async (time: string, {dispatch, rejectWithValue}) => {
        return await axios(`${api}/status/${time}`).then(({data}) => {
            return {time, data}
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)
export const changePoolsAction = createAsyncThunk(
    'page/changePoolsAction',
    async (pools: PoolType[], {dispatch, rejectWithValue}) => {
        return await axios.post(`${api}/pools`, pools).then(({data}) => {
            message.success("Пулы успешно изменены!")
            dispatch(getUserAction())
            return data
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)
export const changePasswordAction = createAsyncThunk(
    'page/changePasswordAction',
    async (fields: { Cur: string, New: string }, {dispatch, rejectWithValue}) => {
        return await axios.post(`${api}/password`, fields).then(({data}) => {
            message.success("Пароль успешно изменен!")
            return data
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)
export const editModeAction = createAsyncThunk(
    'page/editModeAction',
    async ({name, mode}: { name: string, mode: 0 | 1 | 2 | 3 | 4 }, {dispatch, rejectWithValue}) => {
        return await axios.post(`${api}/${name}/mode/${mode}`).then(({data}) => {
            message.success("Режим успешно изменен!")
            return data
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)
export const editPercentAction = createAsyncThunk(
    'page/editPercentAction',
    async ({name, percent}: { name: string, percent: number }, {dispatch, rejectWithValue}) => {
        return await axios.post(`${api}/${name}/percent/${percent}`).then(({data}) => {
            message.success("Процент успешно изменен!")
            return data
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)
export const changeExceptionAction = createAsyncThunk(
    'page/changeExceptionAction',
    async ({name, presets}: { name: string, presets: StatusType['Presets'] }, {dispatch, rejectWithValue}) => {
        return await axios.post(`${api}/${name}/presets`, presets).then(({data}) => {
            message.success("Пресеты успешно изменены!")
            dispatch(getStatusAction())
            return data
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)
// export const getTrackerStatusAction = createAsyncThunk(
//     'page/getTrackerStatusAction',
//     async (_, {dispatch, getState, rejectWithValue,}) => {
//         const {
//             page: {
//                 currentTrackerId
//             }
//         } = getState() as RootState;
//         if (currentTrackerId !== undefined)
//             return await axios(`${api}/trackers/${currentTrackerId}/status`).then(({data}) => {
//                 return [currentTrackerId, data]
//             }).catch(({response}) => {
//                 return rejectWithValue(response?.data || "Error")
//             })
//     }
// )
// export const getTrackersAction = createAsyncThunk(
//     'page/getTrackersAction',
//     async ({params}: { params?: any }, {dispatch, rejectWithValue}) => {
//         return await axios(`${api}/trackers`, {
//             params: {
//                 visits: true,
//                 // count: 100
//                 ...params
//             }
//         }).then(({data}) => {
//             return data
//         }).catch(({response}) => {
//             return rejectWithValue(response?.data || "Error")
//         })
//     }
// )
// export const getPlansAction = createAsyncThunk(
//     'page/getPlansAction',
//     async (_, {dispatch, rejectWithValue}) => {
//         return await axios(`${api}/plans`).then(({data}) => {
//             return data
//         }).catch(({response}) => {
//             return rejectWithValue(response?.data || "Error")
//         })
//     }
// )
// export const deleteTrackerAction = createAsyncThunk(
//     'page/deleteTrackerAction',
//     async (Id: any, {dispatch, getState, rejectWithValue}) => {
//         const {
//             page: {
//                 dateFilter,
//                 rangePickerValue
//             }
//         } = getState() as RootState;
//
//         return await axios.delete(`${api}/trackers/${Id}`).then(({data}) => {
//             if (dateFilter !== null) {
//                 dispatch(getTrackersAction({
//                     params: {
//                         start: dateFilter[0]?.format("YYYY-MM-DD"),
//                         end: dateFilter[1]?.format("YYYY-MM-DD")
//                     }
//                 }))
//             } else if (rangePickerValue !== undefined) {
//                 dispatch(getTrackersAction({
//                     params: {
//                         start: rangePickerValue[0]?.format("YYYY-MM-DD"),
//                         end: rangePickerValue[1]?.format("YYYY-MM-DD")
//                     }
//                 }))
//             }
//             return data
//         }).catch(({response}) => {
//             return rejectWithValue(response?.data || "Error")
//         })
//     }
// )
export const pageSlice = createSlice({
    name: "page",
    initialState,
    reducers: {
        clearUserAction(state) {
            state.user = null
        },
        // changeCurrentTrackerId(state, action: PayloadAction<number | undefined>) {
        //     state.currentTrackerId = action.payload
        // },
        // changeDateFilterAction(state, action: PayloadAction<any>) {
        //     state.dateFilter = action.payload
        // },
        // changeRangePickerValueAction(state, action: PayloadAction<any>) {
        //     state.rangePickerValue = action.payload
        // }
    },
    extraReducers: (builder) => {
        builder.addCase(authAction.fulfilled, (state, action: any) => {
            state.user = action.payload
            History.push("/")
        })
        builder.addCase(getPoolsAction.fulfilled, (state, action: any) => {
            state.pools = action.payload
        })
        builder.addCase(getRateAction.fulfilled, (state, action: any) => {
            state.rate[action.payload.time] = action.payload.data
        })
        builder.addCase(getTimedStatusAction.fulfilled, (state, action: any) => {
            state.timed_status[action.payload.time] = action.payload.data
        })
        builder.addCase(getWorkersAction.fulfilled, (state, action: any) => {
            state.workers = action.payload
        })
        builder.addCase(getStatusAction.fulfilled, (state, action: any) => {
            state.status = action.payload
        })
        builder.addCase(changePoolsAction.fulfilled, (state, action: any) => {
            state.pools = action.payload
        })
        builder.addCase(getUserAction.fulfilled, (state, action: any) => {
            state.user = action.payload
            state.loading = false
        })
        // builder.addCase(getTrackersAction.fulfilled, (state, action: any) => {
        //     state.trackers = action.payload
        // })
        // builder.addCase(getPlansAction.fulfilled, (state, action: any) => {
        //     state.plans = action.payload
        // })
        // builder.addCase(getTrackerStatusAction.fulfilled, (state, action: any) => {
        //     state.trackerStatuses[action.payload[0]] = action.payload[1]
        // })
        // builder.addCase(createTrackerAction.fulfilled, (state, action: any) => {
        //     state.currentTrackerId = action.payload.Id
        // })
        builder.addCase(regAction.fulfilled, (state, action: any) => {
            state.user = action.payload
            History.push("/")
            // message.success("На вашу почту отправлен пароль для авторизации!")
        })
        builder.addCase(authAction.rejected, (state, action: any) => {
            message.error("Error: " + action.payload || "Error")
        })
        builder.addCase(getPoolsAction.rejected, (state, action: any) => {
            message.error("Error: " + action.payload || "Error")
        })
        builder.addCase(getRateAction.rejected, (state, action: any) => {
            message.error("Error: " + action.payload || "Error")
        })
        builder.addCase(getTimedStatusAction.rejected, (state, action: any) => {
            message.error("Error: " + action.payload || "Error")
        })
        builder.addCase(getWorkersAction.rejected, (state, action: any) => {
            message.error("Error: " + action.payload || "Error")
        })
        builder.addCase(getStatusAction.rejected, (state, action: any) => {
            message.error("Error: " + action.payload || "Error")
        })
        builder.addCase(changePasswordAction.rejected, (state, action: any) => {
            message.error("Error: " + action.payload || "Error")
        })
        builder.addCase(changePoolsAction.rejected, (state, action: any) => {
            message.error("Error: " + action.payload || "Error")
        })
        builder.addCase(getUserAction.rejected, (state, action: any) => {
            // message.error(action.payload || "Error")
            state.loading = false
        })
        builder.addCase(regAction.rejected, (state, action: any) => {
            message.error("Error: " + action.payload || "Error")
        })
        builder.addCase(editModeAction.rejected, (state, action: any) => {
            message.error("Error: " + action.payload || "Error")
        })
        builder.addCase(editPercentAction.rejected, (state, action: any) => {
            message.error("Error: " + action.payload || "Error")
        })
        builder.addCase(changeExceptionAction.rejected, (state, action: any) => {
            message.error("Error: " + action.payload || "Error")
        })
        // builder.addCase(createTrackerAction.rejected, (state, action: any) => {
        //     message.error(action.payload || "Error")
        // })
        // builder.addCase(getPlansAction.rejected, (state, action: any) => {
        //     message.error(action.payload || "Error")
        // })
    }
})
export const {
    // changeRangePickerValueAction,
    // changeDateFilterAction,
    // changeNewProjectAction,
    // changeCurrentTrackerId,
    clearUserAction
} = pageSlice.actions
export default pageSlice.reducer